import {Component, EntityListSubTree, Field, HasMany, useEntityList, useEntityListSubTree} from '@contember/admin'
import * as React from 'react'
import {BulkFilterContext} from './BlockEditor'

export const CleverMapsSelectField = Component(
	() => {
		const stackEntityAccessor = React.useContext(BulkFilterContext)
		const cleverMapsName = stackEntityAccessor.getField('name').value
		const cleverMaps = useEntityListSubTree('cleverMaps')
		const entities = useEntityList('form.cleverMaps')
		const entity = Array.from(cleverMaps).find((it) => it.name === cleverMapsName)

		React.useEffect(() => {
			if (entity) {
				entities.connectEntity(entity)
			} else {
				entities.connectEntity(stackEntityAccessor)
			}
		}, [entities, entity, stackEntityAccessor])

		return null
	},
	() => (
		<>
			<EntityListSubTree entities="CleverMap" alias="cleverMaps">
				<Field field="name" />
				<Field field="viewName" />
				<Field field="shareId" />
				<Field field="showMeasure" />
				<Field field="showCompare" />
				<Field field="showSearch" />
				<Field field="infoBoxDefaultExpanded" />
				<Field field="showProjectControls" />
				<Field field="showViewSwitch" />
				<Field field="showQuickShare" />
				<Field field="showQuickExport" />
				<Field field="fullScreenButton" />
				<Field field="interactivityAfterClick" />
				<Field field="themeLogoUrl" />
				<Field field="themePrimaryColor" />
				<HasMany field="forms" />
			</EntityListSubTree>
			<HasMany field="form.cleverMaps">
				<Field field="name" />
				<Field field="viewName" />
				<Field field="shareId" />
				<Field field="showMeasure" />
				<Field field="showCompare" />
				<Field field="showSearch" />
				<Field field="infoBoxDefaultExpanded" />
				<Field field="showProjectControls" />
				<Field field="showViewSwitch" />
				<Field field="showQuickShare" />
				<Field field="showQuickExport" />
				<Field field="fullScreenButton" />
				<Field field="interactivityAfterClick" />
				<Field field="themeLogoUrl" />
				<Field field="themePrimaryColor" />
				<HasMany field="forms" />
			</HasMany>
		</>
	),
	'CleverMapsSelectField',
)
