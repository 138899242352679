import React, { useCallback } from 'react';
import { EntityAccessor } from '@contember/admin';
import { FormControl, Slider, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { CleverMapsSDKContext } from './cleverMapsSDK';
import { resetIframeFilters } from '../services/iframeFilter';
import { useHandleIFrameSubscription } from '../hooks/cleverMaps/useHandleIFrameSubscription';

const ContemberSlider = styled(Slider)({
    color: 'var(--cui-control-color)',
    height: 3,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
        backgroundColor: '#fff',
        border: '1px solid currentColor',
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
        },
        '& .contember-bar': {
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    '& .MuiSlider-track': {
        height: 3,
    },
    '& .MuiSlider-rail': {
        height: 3,
    },
})

type RangeFieldProps = {
    field: EntityAccessor;
    reference: { form: EntityAccessor };
}

const rangeFieldValue = (filterValues: [number, number] = null, valuesArray: EntityAccessor[], minValue: number, maxValue: number): number[] => {
    if (filterValues && filterValues.length === 2) {
        if (filterValues[1] === null) {
            return [Number(filterValues[0]), maxValue]
        }
        return filterValues
    } else if (valuesArray.length) {
        return valuesArray.map((value) => Number(value.getField<string | number>('value').value!))
    } else {
        return [minValue, maxValue]
    }
}

export const RangeField: React.FC<RangeFieldProps> = ({ field, reference }) => {
    const { iframes, activeFiltersCount, increaseFiltersCount } = React.useContext(CleverMapsSDKContext)
    const cleverMapIds = Array.from(reference.form.getEntityList('cleverMaps')).map((cleverMap) => cleverMap.getField('id').value)
    const fieldId = field.id as string
    const filterName = field.getField<string>('name').value
    const label = field.getField<string>('label').value
    const valuesArray = Array.from(field.getEntityList({ field: 'values' }))
    const options = field.getEntity('options')
    const maxValue = options.getField<number>('max').value ?? 0
    const minValue = options.getField<number>('min').value ?? 100
    const [filterId, setFilterId] = React.useState<null | string>(null)
    const [filterValue, setFilterValue] = React.useState<null | [number, number]>(null)
    const [sliderValue, setSliderValue] = React.useState<null | [number, number]>(null)

    React.useEffect(() => {
        if (activeFiltersCount === 0) {
            resetIframeFilters(iframes, cleverMapIds, filterId, setFilterId, setFilterValue)
        }
    }, [activeFiltersCount, cleverMapIds, filterId, iframes])

    const addFilters = useCallback((...params) => {
        const values = params[1]?.values
        if (params[2].property === filterName) {
            increaseFiltersCount()
            if (!values) {
                setFilterId(params[0])
            } else {
                setFilterId(params[0])
                setFilterValue(values)
                setSliderValue(values)

            }
        }
    }, [filterName, increaseFiltersCount])

    useHandleIFrameSubscription(cleverMapIds, addFilters)

    const onValueChange = (newValue: number | number[]) => {
        const values = newValue as [number, number]
        if (!filterValue) {
            increaseFiltersCount()
        }
        iframes
            .filter((iframe) => cleverMapIds.includes(iframe.cleverMapId))
            .forEach((it) => {
                if (!filterValue && !filterId) {
                    it.iframe.message.addFilter(filterName, { values: values }, filterName)
                } else {
                    it.iframe.message.setFilter(filterId, { values: values })
                    setFilterValue(values)
                }
            })
    }

    return (
        <FormControl key={fieldId}>
            <Typography gutterBottom>{label}</Typography>
            <ContemberSlider
                valueLabelDisplay="auto"
                value={rangeFieldValue(sliderValue, valuesArray, minValue, maxValue)}
                max={maxValue}
                min={minValue}
                step={options.getField<number>('step').value ?? 1}
                onChange={(_, newValue) => {
                    const values = newValue as [number, number]
                    setSliderValue(values)
                }}
                onChangeCommitted={(_, newValue) => {
                    onValueChange(newValue)
                }}
            />
        </FormControl>
    )
}
