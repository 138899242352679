import { Link, PersistButton } from '@contember/admin'
import { ChevronLeftIcon, EyeIcon } from '@heroicons/react/24/outline'
import * as React from 'react'
import Logo from '../svgs/Logo'

export type HeaderProps = {
	actions?: React.ReactNode
	showViewStory?: boolean
	showViewChapter?: boolean
	backButton?: {
		to: string
		text: string
	}
}

export const Header = ({ actions, backButton, showViewStory, showViewChapter }: HeaderProps) => (
	<header className="bg-clever-bg w-full sticky top-0 z-10 px-4 sm:px-6 lg:px-8 flex items-center justify-between">
		<div className="flex items-center gap-10">
			<div>
				<Link to="storyList">
					<span className="sr-only">CleverMaps</span>
					<Logo />
				</Link>
			</div>
			<div className="flex gap-5">
				{backButton && (
					<Link to={backButton.to} className="flex items-center gap-2 bg-transparent py-2 px-4 text-base font-medium text-white hover:bg-opacity-90">
						<span className="h-5 w-5">
							<ChevronLeftIcon />
						</span>
						<span>{backButton.text}</span>
					</Link>
				)}
			</div>
		</div>
		<div className="flex items-center gap-5">
			<div>
				{showViewStory && (
					<Link target="_blank" to="viewStory(story: $entity.id)" className="flex items-center gap-2 bg-transparent py-2 px-4 border border-transparent rounded-md text-base font-medium text-white hover:bg-opacity-90">
						<span className="h-5 w-5">
							<EyeIcon />
						</span>
						<span>View story</span>
					</Link>
				)}
				{showViewChapter && (
					<Link target="_blank" to="viewChapter(chapter: $entity.id)" className="flex items-center gap-2 bg-transparent py-2 px-4 border border-transparent rounded-md text-base font-medium text-white hover:bg-opacity-90">
						<span className="h-5 w-5">
							<EyeIcon />
						</span>
						<span>View chapter</span>
					</Link>
				)}
			</div>
			<div>
				{actions &&
					<>
						{actions}
					</>
				}
				{!actions && <PersistButton />}
			</div>
		</div>
	</header>
)
