import { useCleverMapsMembership } from './useCleverMapsMembership';

export const useCleverMapsCanEdit = (props: {
    projectId: string | undefined;
}) => {
    const { response: membership, error } = useCleverMapsMembership({
        projectId: props.projectId,
    });
    if (!membership) {
        return null;
    }
    if (error) {
        return false;
    }
    return (
        ['ADMIN', 'VIEW_CREATOR', 'EDITOR'].includes(membership.role) &&
        membership.status === 'ENABLED'
    );
};
