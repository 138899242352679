// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import { StoryCreateForm } from '../components/forms/storyForms'
import { CreateScope } from '../components/CreateScope'
import { Layout } from '../components/Layout'
import { Header } from '../components/layout/Header'
import { PersistButton } from '@contember/admin'

const StoryCreate = () => {
	return (
		<Layout>
			<CreateScope entity="Story" redirectOnSuccess="storyEdit(id: $entity.id)">
				<Header actions={null} />
				<div className="container max-w-2xl mx-auto">
					<div className="mt-10 p-8 rounded-lg shadow-sm border border-slate-100">
						<h1 className="font-bold text-2xl text-black">Create your new story</h1>
						<StoryCreateForm />
						<PersistButton size="large" className="bg-slate-100 w-1/4 button-blue" labelSaved="Create story" labelSave="Create story" />
					</div>
				</div>
			</CreateScope>
		</Layout>
	)
}

export default StoryCreate;