import { EntityAccessor } from '@contember/admin'

export const richTextBlockParser = (part: EntityAccessor, { isInside = false }) => ({
	id: part.id,
	json: part.getField('json').value,
	references: Array.from(part.getEntityList('references')).map((reference) => ({
		id: reference.id,
		type: reference.getField('type').value,
		target: reference.getEntity('target'),
		targetCleverMaps: reference.getEntityList('targetCleverMaps'),
		targetCleverMapLinkItems: reference.getEntityList('targetCleverMapLinkItems'),
		form: reference.getEntity('form'),
		stack: isInside ? [] : reference.getEntity('stack'),
		image: reference.getEntity('image'),
		embed: isInside ? null : reference.getEntity('embed'),
	})),
})
