// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import { Component, Field, HasMany } from '@contember/admin'
import { ContentFields } from './ContentFields'

export const StoryFields = Component(
	() => (
		<>
			<Field field="headerHeadline" />
			<Field field="headerSubheading" />
			<Field field="headerImage.url" />
			<Field field="title" />
			<Field field="cleverMapsId" />
			<Field field="useStyle" />
			<Field field="style" />
			<Field field="useMobileHeader" />
			<ContentFields />
			<HasMany field="chapters" orderBy="order">
				<Field field="title" />
			</HasMany>
		</>
	),
	'StoryFields',
)
