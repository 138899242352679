import { Button, Component, Field, Stack, SugarableRelativeSingleField, useDialog, useField, useMutationState, usePersistWithFeedback } from '@contember/admin'
import * as React from 'react'

export type ArchiveEntityButtonProps = {
	children: React.ReactNode
	field: SugarableRelativeSingleField | string
}

export const ArchiveEntityButton = Component<ArchiveEntityButtonProps>(
	({ children, field }) => {
		const archiveField = useField(field)
		const triggerPersist = usePersistWithFeedback()
		const isMutating = useMutationState()
		const dialog = useDialog()

		const onClick = React.useCallback(async () => {
			const ok = await dialog.openDialog({
				content: ({ resolve }) => (
					<Stack direction="vertical" gap="large">
						<div>
							Opravdu chcete smazat tento záznam?
						</div>
						<Stack direction="horizontal" justify="space-between">
							<Button onClick={() => resolve()}>Zrušit</Button>
							<Button onClick={() => resolve(true)} intent="danger">Smazat</Button>
						</Stack>
					</Stack>
				)
			})

			if (!ok) return
			archiveField.updateValue((new Date()).toISOString())
			triggerPersist().catch(() => { })
		}, [triggerPersist])

		return <Button intent="danger" className="shadow-none rounded-full" onClick={onClick} disabled={isMutating}>{children}</Button>
	},
	({ children, field }) => (
		<>
			<Field field={field} />
			{children}
		</>
	),
	'ArchiveEntityButton',
)
