import * as React from 'react'
import { EntityAccessor, EntityListAccessor } from '@contember/admin'

import { CleverMapsSDKContext } from '../../cleverMapsSDK'
import { createIframeFilterId } from './CleverMapForm'
import filterValueTransform from '../../../utils/filterValueTransform'

type CleverMapBulkFilterProps = {
	reference: { targetCleverMaps: EntityListAccessor; form: EntityAccessor }
	children: React.ReactElement
}

export const CleverMapBulkFilter = ({ reference, children }: CleverMapBulkFilterProps) => {
	const { iframes } = React.useContext(CleverMapsSDKContext)
	const cleverMapIds = Array.from(reference.targetCleverMaps.getAccessor()).map((cleverMap) => cleverMap.getField('id').value)
	return (
		// eslint-disable-next-line jsx-a11y/anchor-is-valid
		<a
			href="#"
			onClick={(e) => {
				e.preventDefault()
				iframes
					.filter((iframe) => cleverMapIds.includes(iframe.cleverMapId))
					.forEach((it) => {
						Array.from(reference.form.getEntityList('fields')).map((field) => {
							const filterName = field.getField<string>('name').value!
							const valuesArray = Array.from(field.getEntityList('values'))
							it.iframe.message.removeFilter(createIframeFilterId(it.iframe.cleverMapId, filterName))
							it.iframe.message.addFilter(
								filterName,
								{
									values: valuesArray.map((value) => filterValueTransform(value.getField<string>('value').value)),
								},
								createIframeFilterId(it.cleverMapId, filterName),
							)
						})
					})
			}}
		>
			{children}
		</a>
	)
}
