import { EntityAccessor, FieldContainer, Stack } from '@contember/admin';
import Checkbox from '@mui/material/Checkbox'
import { FormControl, FormControlLabel } from '@mui/material';
import React, { useCallback } from 'react';
import { CleverMapsSDKContext } from './cleverMapsSDK';
import { resetIframeFilters } from '../services/iframeFilter';
import filterValueTransform from '../utils/filterValueTransform';
import { useHandleIFrameSubscription } from '../hooks/cleverMaps/useHandleIFrameSubscription';
import iframeValueTransform from '../utils/iframeValueTransform';

type MultiSelectFieldProps = {
    field: EntityAccessor;
    reference: { form: EntityAccessor };
}

export const CheckboxesField: React.FC<MultiSelectFieldProps> = ({ field, reference }) => {
    const { iframes, activeFiltersCount, increaseFiltersCount, decreaseFiltersCount } = React.useContext(CleverMapsSDKContext)
    const cleverMapIds = Array.from(reference.form.getEntityList('cleverMaps')).map((cleverMap) => cleverMap.getField('id').value)
    const valuesArray = Array.from(field.getEntityList({ field: 'values' }))
    const filterName = field.getField<string>('name').value
    const [filterId, setFilterId] = React.useState<null | string>(null)
    const [filterValue, setFilterValue] = React.useState<null | Array<string>>(null)

    React.useEffect(() => {
        if (activeFiltersCount === 0) {
            resetIframeFilters(iframes, cleverMapIds, filterId, setFilterId, setFilterValue)
        }
    }, [activeFiltersCount, cleverMapIds, filterId, iframes])

    const addFilters = useCallback((...params) => {
        const values = params[1]?.values
        if (params[2].property === filterName) {
            increaseFiltersCount()
            if (!values) {
                setFilterId(params[0])
            } else {
                setFilterValue(values.map((value) => iframeValueTransform(value)))
                setFilterId(params[0])
            }
        }
    }, [filterName, increaseFiltersCount])

    useHandleIFrameSubscription(cleverMapIds, addFilters)

    const onValueChange = (event: React.ChangeEvent<HTMLInputElement>, value: EntityAccessor) => {
        const isChecked = event.target.checked
        const fieldValue = value.getField<string>('value').value
        const checkedValues = filterValue ?? []
        if (isChecked) {
            checkedValues.push(fieldValue)
        } else {
            const valueIndex = checkedValues.indexOf(fieldValue)
            checkedValues.splice(valueIndex, 1)
        }
        if (!checkedValues.length) {
            resetIframeFilters(iframes, cleverMapIds, filterId, setFilterId, setFilterValue)
            decreaseFiltersCount()
        } else {
            iframes
                .filter((iframe) => cleverMapIds.includes(iframe.cleverMapId))
                .forEach((it) => {
                    if (filterValue && checkedValues.length) {
                        it.iframe.message.setFilter(
                            filterId,
                            {
                                values: checkedValues.map((value) => filterValueTransform(value)),
                            }
                        )
                        setFilterValue(checkedValues.length ? [...checkedValues] : null)
                    } else if (!filterValue) {
                        it.iframe.message.addFilter(
                            filterName,
                            {
                                values: checkedValues.map((value) => filterValueTransform(value)),
                            },
                            filterName
                        )
                    }
                })
        }
    }

    return (
        <>
            <FieldContainer label={<>{field.getField('label').value}</>}>
                <Stack direction="vertical" gap="none">
                    <FormControl>
                        {valuesArray.map((value, i) => (
                            <FormControlLabel
                                key={i}
                                control={
                                    <Checkbox
                                        color="primary"
                                        onChange={(event => {
                                            onValueChange(event, value)
                                        })}
                                        checked={Boolean(
                                            filterValue && filterValue.includes(value.getField<string>('value').value),
                                        )}
                                    />
                                }
                                label={<>{value.getField('label').value}</>}
                            />
                        ))}
                    </FormControl>
                </Stack>
            </FieldContainer>
        </>
    )
}
