import { Component, useDirtinessState, LinkButton as LB, LinkButtonProps, useDialog, Stack, Button } from '@contember/admin'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'

export const LinkButton = Component<LinkButtonProps>(({ to, children }) => {
	const dialog = useDialog()
	const isDirty = useDirtinessState()

	return (
		<LB
			to={to}
			disabled={isDirty}
			onClick={async (e) => {
				if (isDirty) {
					e.preventDefault()
					await dialog.openDialog({
						content: ({ resolve }) => (
							<Stack direction="vertical" gap="large">
								<div>Please save the story first. Than continue to editing chapters.</div>
								<Stack direction="horizontal" justify="center">
									<Button onClick={() => resolve()}>Understand</Button>
								</Stack>
							</Stack>
						),
					})

					return
				}
			}}
		>
			{children}
		</LB>
	)
})
