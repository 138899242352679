import * as React from 'react'
import {
	ContemberClient,
	DialogProvider,
	EnvironmentContext,
	I18nProvider,
	IdentityContext,
	Pages,
	pathToRequestState,
	RequestProvider,
	RoutingContext,
	runReactApp,
	SectionTabsProvider,
	StyleProvider,
	Toaster,
	ToasterProvider,
	useRedirect,
	useSetSessionToken,
} from '@contember/admin'
import '@contember/admin/style.css'
import { ContextType } from 'react'
import { Environment } from '@contember/binding'
import { RoutingContextValue } from '@contember/admin/src/routing'
import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'

import { NavigationProvider } from './components/NavigationProvider'
import CleverMapsSDK from './components/cleverMapsSDK'

import './index.css'
import OktaAuth from '@okta/okta-auth-js'
import { Security, useOktaAuth } from '@okta/okta-react'
import { MemoizedLoginCallback } from './components/MemoizedLoginCallback'

type ContemberConnectionConfig = {
	sessionToken: string
}

const redirectUriPath = '/login-callback'

const oktaAuth = new OktaAuth({
	issuer: 'https://login.secure.clevermaps.io/oauth2/ausblrhqbi0ahjlhY0h7',
	clientId: '0oaxq6v2loD13rlSL0h7',
	redirectUri: window.location.origin + redirectUriPath,
	scopes: ['openid', 'email', 'profile', 'can'],
	pkce: true
})

const fakeIdentityContext: ContextType<typeof IdentityContext> = {
	identity: {
		id: '',
		otpEnabled: false,
		email: '',
		personId: '',
		permissions: {
			canCreateProject: false,
		},
		projects: [],
	},
	clearIdentity: () => {
		throw new Error('Not implemented')
	},
}

const Content: React.FC<ContemberConnectionConfig> = ({ sessionToken }) => {
	const setSessionToken = useSetSessionToken();
	React.useEffect(() => {
		setSessionToken(sessionToken);
	}, [sessionToken, setSessionToken]);
	return <Pages>{import.meta.glob('./pages/**/*.tsx', { eager: true })}</Pages>
}

const StableInnerApp = React.memo<ContemberConnectionConfig>(({ sessionToken }) => {
	return (
		<NavigationProvider>
			<ContemberClient
				apiBaseUrl={import.meta.env.VITE_CONTEMBER_ADMIN_API_BASE_URL}
				sessionToken={sessionToken}
				project={import.meta.env.VITE_CONTEMBER_ADMIN_PROJECT_NAME}
				stage="live"
			>
				<IdentityContext.Provider value={fakeIdentityContext}>
					<CleverMapsSDK>
						<Content sessionToken={sessionToken}></Content>
					</CleverMapsSDK>
				</IdentityContext.Provider>
			</ContemberClient>
		</NavigationProvider>
	)
})

StableInnerApp.displayName = 'StableInnerApp'

const OktaAuthedContemberApp = React.memo(() => {
	const { oktaAuth, authState } = useOktaAuth()
	const isSignedIn = authState?.isAuthenticated === true
	const authStateExists = !!authState
	// It would be cleaner to use reactive usePathname() value instead of location.pathname,
	// but Contember does not provide it 
	// Maybe better to put it in separate page file (file-based routing) ?
	const isLoginRedirect = location.pathname === redirectUriPath

	const signInWithRedirectCheck = React.useCallback(async () => {
		if (
			!authStateExists || // initializing Okta-sdk and smooth token autorenew in progress
			// There is a bug, when trying to redirect during autorenew, don't do it.
			isLoginRedirect || // login redirect in progress, do not redirect again
			import.meta.env.VITE_CONTEMBER_ADMIN_SESSION_TOKEN !== undefined // token passed from evironment
		) {
			console.log('no need to sign in with redirect')
			return;
		}

		if (isSignedIn) { // SB okta token is valid
			const sessionExists = await oktaAuth.session.exists()
			// Check if global Okta session, because token could be valid but user is logged out globally,
			// CM Studio iframes would display auth prompt instead of map dashboard.
			if (sessionExists) {
				console.log('no need to sign in with redirect')
				return;
			}
		}

		console.log('sign in with redirect')
		oktaAuth.setOriginalUri(location.pathname + location.search)
		oktaAuth.signInWithRedirect()
	}, [oktaAuth, authStateExists, isSignedIn, isLoginRedirect])

	React.useEffect(() => {
		signInWithRedirectCheck();
	}, [signInWithRedirectCheck])

	if (import.meta.env.VITE_CONTEMBER_ADMIN_SESSION_TOKEN !== undefined) {
		return <StableInnerApp sessionToken={import.meta.env.VITE_CONTEMBER_ADMIN_SESSION_TOKEN} />
	}

	if (isLoginRedirect) {
		console.log('memoized login callback')
		return <MemoizedLoginCallback loadingElement={<>Not authenticated, redirecting for login.</>} />
	}

	if (!isSignedIn) {
		console.log('not signed in')
		return <>Not authenticated, redirecting for login.</>
	}

	if (!authState?.accessToken?.accessToken) {
		console.log('no accessToken')
		return <>Not authenticated, trying to redirect. In case of issues, please refresh the page.</>
	}

	console.log('stable app')

	return (
		<StableInnerApp sessionToken={authState?.accessToken?.accessToken} />
	)
})

OktaAuthedContemberApp.displayName = 'OktaAuthedContemberApp'

const routing: RoutingContextValue = {
	basePath: '/',
	routes: { index: { path: '/' } },
	defaultDimensions: {},
}

const EntrypointWithoutClient = React.memo(({ children }: { children: React.ReactNode }) => {
	const rootEnv = Environment.create()

	return (
		<StyleProvider>
			<EnvironmentContext.Provider value={rootEnv}>
				<I18nProvider localeCode={undefined} dictionaries={undefined}>
					<ToasterProvider>
						<DialogProvider>
							<NavigationProvider>
								<SectionTabsProvider>{children}</SectionTabsProvider>
							</NavigationProvider>
							<Toaster />
						</DialogProvider>
					</ToasterProvider>
				</I18nProvider>
			</EnvironmentContext.Provider>
		</StyleProvider>
	)
})

EntrypointWithoutClient.displayName = 'EntrypointWithoutClient'

Sentry.init({
	dsn: 'https://a0e3c7328d93b1bec0625500df04eee3@o99157.ingest.sentry.io/4505873668046848',
	integrations: [
		new Sentry.BrowserTracing({
			tracePropagationTargets: [/^https:\/\/story\.clevermaps\.io/, /https?:\/\/((secure|staging)\.)?clevermaps\.io/],
		}),
	],
	tracesSampleRate: 1.0,
});

const SecurityProvider = ({ children }) => {
	const redirect = useRedirect()
	return <Security oktaAuth={oktaAuth} restoreOriginalUri={async (self, originalUri): Promise<void> => {
		const url = new URL(originalUri ?? '/', location.origin)
		redirect(pathToRequestState(routing, url.pathname, url.search))
	}}>
		{children}
	</Security>;
}

const App = () => {
	return <EntrypointWithoutClient>
		<RoutingContext.Provider value={routing}>
			<RequestProvider>
				<SecurityProvider>
					<OktaAuthedContemberApp />
				</SecurityProvider>
			</RequestProvider>
		</RoutingContext.Provider>
	</EntrypointWithoutClient>;
}

runReactApp(
	<App />,
	null,
	(dom, react, onRecoverableError) => createRoot(dom, { onRecoverableError }).render(react),
)
