import { GetProjectResponse } from '../../clevermaps-js-sdk'
import { useCleverMapsGetEndpoint } from '../useCleverMapsGetEndpoint'

export const useCleverMapsProject = (props: { projectId: string | null | undefined }) => {
	const url = props.projectId ? `/rest/projects/${props.projectId}?size=1000&page=0` : null
	const { response, error } = useCleverMapsGetEndpoint<GetProjectResponse>(url)
	if (error) {
		throw new Error(String(error))
	}

	return response
}
