import * as React from 'react'
import { EntityId, EntityListAccessor, Link } from '@contember/admin'
import clsx from 'clsx'

import Logo from '../svgs/Logo'

export type Chapter = {
	id: string
	title: string
}

type HeaderProps = {
	title: string
	chapters: EntityListAccessor
	currentChapterId?: string | number
	storyId: EntityId
	isMobileHeader?: boolean
}

export const Header = ({ title, chapters, currentChapterId, storyId, isMobileHeader }: HeaderProps) => {
	const [showMobileMenu, setShowMobileMenu] = React.useState(false)

	return (
		<header>
			<div className="relative bg-white">
				<div className="relative  max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
					<div className="flex justify-between items-center md:space-x-10">
						<div className="flex justify-start lg:w-0 lg:flex-1">
							<div className="flex gap-4 items-center text-black font-semibold">
								<Logo />
								<span className="text-xl uppercase">{title}</span>
							</div>
						</div>
						<div className={clsx(['md:-mr-2', 'md:-my-2'], { ['md:hidden']: !isMobileHeader })}>
							<button
								onClick={() => setShowMobileMenu(!showMobileMenu)}
								type="button"
								className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
								aria-expanded="false"
							>
								<span className="sr-only">Open menu</span>
								<svg
									className="h-6 w-6"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth="2"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
								</svg>
							</button>
						</div>
						<nav className={clsx(['space-x-10', 'hidden'], { ['md:flex']: !isMobileHeader })}>
							<Link to={`viewStory(story: '${storyId}')`} className="text-base font-medium text-gray-500 hover:text-gray-900">
								Home
							</Link>
							{Array.from(chapters).map((chapter) => (
								<Link
									key={chapter.id}
									to={`viewChapter(chapter: '${chapter.id}')`}
									className={`text-base font-medium text-gray-${chapter.id === currentChapterId ? '900' : '500'} hover:text-gray-900`}
								>
									<>{chapter.getField('title').value}</>
								</Link>
							))}
						</nav>
					</div>
					<div
						className={clsx(
							[
								'absolute',
								'top-full',
								'left-4 sm:left-6',
								'right-4 sm:right-6',
								'z-40',
								'rounded-lg',
								'shadow-lg',
								'ring-1',
								'ring-black',
								'ring-opacity-5',
								'bg-white',
								'divide-y-2',
								'divide-gray-50',
								'py-6',
								'px-6',
								'm-0',
							],
							{
								['hidden']: !showMobileMenu,
								['md:hidden']: !isMobileHeader,
							},
						)}
					>
						<nav className="grid grid-cols-1 gap-7">
							<Link to={`viewStory(story: '${storyId}')`} className="text-base font-medium text-gray-500 hover:text-gray-900">
								Home
							</Link>
							{Array.from(chapters).map((chapter) => (
								<Link
									key={chapter.id}
									to={`viewChapter(chapter: '${chapter.id}')`}
									className={`text-base font-medium text-gray-${chapter.id === currentChapterId ? '900' : '500'} hover:text-gray-900`}
								>
									<>{chapter.getField('title').value}</>
								</Link>
							))}
						</nav>
					</div>
				</div>
			</div>
		</header>
	)
}
