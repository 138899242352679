import { ListDatasetsResponse } from '../../clevermaps-js-sdk'
import { useCleverMapsGetEndpoint } from '../useCleverMapsGetEndpoint'

export const useCleverMapsListDatasets = (props: { projectId: string | null | undefined }) => {
	const url = props.projectId ? `/rest/projects/${props.projectId}/md/datasets?type=dwh&size=1000&page=0&sort=name%2Casc` : null
	const { response, error } = useCleverMapsGetEndpoint<ListDatasetsResponse>(url)
	if (error) {
		throw new Error(String(error))
	}

	return response
}
