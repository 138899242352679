import * as React from 'react'
import {
	Box,
	Button,
	Component,
	Dropdown,
	EditorRenderElementProps,
	EditorTransforms,
	Field,
	HasMany,
	HasOne,
	Icon,
	InitializeReferenceContentProps,
	Repeater,
	Stack,
	TextField,
	useEditor,
} from '@contember/admin'
import { CleverMapLinkSelectField } from '../CleverMapLinkSelectField'

export const InsertCleverMapLink = Component<InitializeReferenceContentProps>(
	({ onSuccess, onCancel }) => {
		return (
			<div className="dialog-content-wrapper">
				<Repeater field="targetCleverMapLinkItems" label="" sortableBy="order" addButtonText="Add map">
					<CleverMapLinkSelectField />
					<TextField field="state" label="Field state" />
				</Repeater>
				<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1em', minWidth: '350px' }}>
					<Button onClick={onCancel}>{'Cancel'}</Button>
					<Button intent="primary" onClick={() => onSuccess({ createElement: { type: 'linkCleverMap' } })}>
						Insert
					</Button>
				</div>
			</div>
		)
	},
	() => (
		<>
			<HasMany field="targetCleverMapLinkItems">
				<Field field="order" />
				<HasOne field="targetCleverMap">
					<Field field="name" />
				</HasOne>
				<Field field="state" />
			</HasMany>
		</>
	),
	'InsertCleverMapLink',
)

export const LinkCleverMapElement = (props: EditorRenderElementProps) => {
	const editor = useEditor()

	return (
		<span {...props.attributes} style={{ color: 'var(--cui-control-color)' }}>
			{props.children}
			<span contentEditable={false}>
				<Dropdown
					renderToggle={({ ref, onClick }) => (
						<button ref={ref as React.LegacyRef<HTMLButtonElement>} onClick={onClick} className="editorButton">
							<Icon blueprintIcon="map" />
						</button>
					)}
				>
					<div className="dialog-content-wrapper">
						<Box>
							<Repeater field="targetCleverMapLinkItems" label="" sortableBy="order" addButtonText="Add map">
								<CleverMapLinkSelectField />
								<TextField field="state" label="Field state" />
							</Repeater>
							<Stack direction="vertical">
								<Button intent="danger" onClick={() => EditorTransforms.unwrapNodes(editor, { at: [], match: (node) => node === props.element })}>
									Remove link
								</Button>
							</Stack>
						</Box>
					</div>
				</Dropdown>
			</span>
		</span>
	)
}
