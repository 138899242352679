import { useCleverMapsGetEndpoint } from '../useCleverMapsGetEndpoint'

export const useCleverMapsListProjectSettings = (props: { projectId: string | null | undefined }) => {
	const url = props.projectId ? `/rest/projects/${props.projectId}/md/projectSettings?size=1000&page=0&sort=name%2Casc` : null
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const { response, error } = useCleverMapsGetEndpoint<any>(url)

	if (error) {
		throw new Error(String(error))
	}

	return response
}
