// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import { Component, Stack, TextField } from '@contember/admin'
import { BlockEditor } from '../BlockEditor'
import { ProjectIdProvider } from '../ProjectIdContext'



export const ChapterForm = Component(
    () => (
        <ProjectIdProvider field="story.cleverMapsId">
            <div className="flex mt-8">
                <div className="flex-grow">
                    <Stack direction="vertical">
                        <TextField field="title" label="Title" />
                        <BlockEditor />
                    </Stack>
                </div>
            </div>
        </ProjectIdProvider>
    )
)
