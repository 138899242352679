import { FilterGroup, ListDatasetsResponse } from '../clevermaps-js-sdk'

const filterGroupsToPropertyNames = (filterGroups: FilterGroup[], datasets: ListDatasetsResponse) => {
	return filterGroups
		?.filter((it) => it.property)
		.filter((it) => it.type !== 'globalDate')
		.map((filter) => ({
			value: filter.property,
			label:
				datasets?.content
					.find((it) => it.name === filter.property.split('.')[0])
					?.ref?.properties.find((it) => it.name === filter.property.split('.')[1])?.title ?? filter.property,
		}))
}

export default filterGroupsToPropertyNames
