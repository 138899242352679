import {
	useEnvironment,
	useRoutingLinkFactory,
	NavigationContext,
	isRoutingLinkTarget,
	parseLinkTarget, PageNotFound
} from '@contember/admin';
import * as React from 'react'

export interface NavigationProviderProps {
	children?: React.ReactNode
}

// Copied from admin source - see https://github.com/contember/admin/pull/363
export const NavigationProvider = (props: NavigationProviderProps) => {
	const env = useEnvironment()
	const linkFactory = useRoutingLinkFactory()

	return (
		<NavigationContext.Provider
			value={val => {
				if (!isRoutingLinkTarget(val)) {
					throw new PageNotFound('Invalid request')
				}
				return linkFactory(parseLinkTarget(val, env))
			}}
		>
			{props.children}
		</NavigationContext.Provider>
	)
}
