import * as React from 'react'
import {
	Block,
	BlockEditor as ContemberBlockEditor,
	BlockEditorProps,
	CheckboxField,
	Component,
	EmbedHandlers,
	EntityAccessor,
	HasMany,
	HasOne,
	If,
	ImageUploadField,
	RadioField,
	RichEditor,
	TextField,
	useEntity,
} from '@contember/admin'
import {InsertLink, LinkElement} from './editor/CustomLinks'
import {withAnchorsAsReference} from './editor/AnchorInsertHandler'
import {CleverMapsField} from './CleverMapsField'
import {BulkFilterCleverMapElement, InsertCleverMapBulkFilter} from './editor/CleverMapBulkFilter'
import {FormBuilder} from './FormBuilder'
import {InsertCleverMapLink, LinkCleverMapElement} from './editor/CleverMapLink'
import {Box, Typography} from '@mui/material';

type ContentFieldProps = {
	contentField?: string
	referencesField?: string
	field?: string
	label?: string
	size?: 'large' | 'default'
}

const RB = RichEditor.buttons
const inlineButtons: BlockEditorProps['inlineButtons'] = [
	[RB.bold, RB.italic, RB.underline, RB.strikeThrough],
	[RB.headingOne, RB.headingTwo, RB.headingThree, RB.headingFour],
	[RB.alignStart, RB.alignCenter, RB.alignEnd, RB.alignJustify],
	[RB.unorderedList, RB.orderedList],
	[
		RB.code,
		{
			discriminateBy: 'link',
			referenceContent: InsertLink,
			label: 'Insert link',
			title: 'Insert link',
			blueprintIcon: 'link',
		},
		{
			discriminateBy: 'bulkFilterCleverMap',
			referenceContent: InsertCleverMapBulkFilter,
			label: 'Insert CleverMaps bulk filters',
			title: 'Insert CleverMaps bulk filters',
			blueprintIcon: 'filter-open',
		},
		{
			discriminateBy: 'linkCleverMap',
			referenceContent: InsertCleverMapLink,
			label: 'Insert CleverMaps link',
			title: 'Insert CleverMaps link',
			blueprintIcon: 'map',
		},
	],
]

const blockButtons = [
	{
		discriminateBy: 'cleverMap',
		blueprintIcon: 'map' as const,
		title: 'CleverMaps embedded map',
	},
	{
		discriminateBy: 'image',
		blueprintIcon: 'media' as const,
		title: 'Image',
	},
	{
		discriminateBy: 'cleverMapForm',
		blueprintIcon: 'form' as const,
		title: 'CleverMaps filters',
	},
]

export const BlockEditor = Component<ContentFieldProps>(
	({ contentField, referencesField, field, label, size }) => (
		<ContentProvider>
			<ContemberBlockEditor
				contentField={contentField ?? 'json'}
				referencesField={referencesField ?? 'references'}
				referenceDiscriminationField="type"
				field={field ?? 'content.parts'}
				label={label ?? 'Content'}
				size={size ?? 'default'}
				sortableBy="order"
				blockButtons={blockButtons}
				inlineButtons={inlineButtons}
				embedReferenceDiscriminateBy="embed"
				embedContentDiscriminationField="embed.type"
				embedHandlers={[
					new EmbedHandlers.YouTube({
						discriminateBy: 'youtube',
						youTubeIdField: 'embed.embedId',
					}),
				]}
				augmentEditorBuiltins={(editor) => {
					withAnchorsAsReference(editor, {
						elementType: 'link',
						updateReference: (url, getAccessor) => {
							getAccessor().getField('target.type').updateValue('url')
							getAccessor().getField('target.url').updateValue(url)
						},
					})

					editor.registerElement({
						type: 'link',
						isInline: true,
						render: LinkElement,
					})
					editor.registerElement({
						type: 'bulkFilterCleverMap',
						isInline: true,
						render: BulkFilterCleverMapElement,
					})
					editor.registerElement({
						type: 'linkCleverMap',
						isInline: true,
						render: LinkCleverMapElement,
					})
				}}
			>
				<Block discriminateBy="embed" label="Embed">
					<Block discriminateBy="youtube" label="YouTube video" />
				</Block>
				<Block discriminateBy="cleverMap" label="CleverMaps embedded map">
					<HasOne
						field="stack"
						onInitialize={(getAccessor) => {
							if (!getAccessor().existsOnServer && getAccessor().getField('id').value === null) {
								getAccessor().getField('id').asUuid.setToUuid()
							}
						}}
					>
						<BulkFilterProvider>
							<CleverMapsField field="targetCleverMap" label="Map" />
							<If condition="[targetCleverMap.viewName != null]">
								<RadioField
									field="layout"
									label="Layout"
									orientation="horizontal"
									defaultValue="textRight"
									options={[
										{ label: 'Text right', value: 'textRight' },
										{ label: 'Text left', value: 'textLeft' },
										{ label: 'Text below', value: 'textBelow' },
										{ label: 'Text above', value: 'textAbove' },
									]}
								/>
								<Typography variant={'subtitle2'} component={'h3'}>Frame controls</Typography>
								<Box display={'flex'}>
									<Box sx={{ flex: '1 1 0%' }}>
										<CheckboxField
											field="targetCleverMap.interactivityAfterClick"
											label="After click interactivity"
											defaultValue={true}
										/>
									</Box>
									<Box sx={{ flex: '1 1 0%' }}>
										<CheckboxField
											field="targetCleverMap.fullScreenButton"
											label="Full screen button"
											defaultValue={true}
										/>
									</Box>
									<Box sx={{ flex: '1 1 0%' }}></Box>
								</Box>
								<Typography variant={'subtitle2'} component={'h3'}>Map tools visibility</Typography>
								<Box display={'flex'}>
									<Box sx={{ flex: '1 1 0%' }}>
										<CheckboxField
											field="targetCleverMap.showMeasure"
											label="Measure"
											defaultValue={true}
										/>
									</Box>
									<Box sx={{ flex: '1 1 0%' }}>
										<CheckboxField
											field="targetCleverMap.showCompare"
											label="Compare"
											defaultValue={true}
										/>
									</Box>
									<Box sx={{ flex: '1 1 0%' }}>
										<CheckboxField
											field="targetCleverMap.showSearch"
											label="Search"
											defaultValue={true}
										/>
									</Box>
								</Box>
								<Typography variant={'subtitle2'} component={'h3'}>Info box</Typography>
								<Box display={'flex'}>
									<Box sx={{ flex: '1 1 0%' }}>
										<CheckboxField
											field="targetCleverMap.infoBoxDefaultExpanded"
											label="Default expanded"
											defaultValue={true}
										/>
									</Box>
									<Box sx={{ flex: '1 1 0%' }}>
										<CheckboxField
											field="targetCleverMap.showQuickShare"
											label="Share"
											defaultValue={true}
										/>
									</Box>
									<Box sx={{ flex: '1 1 0%' }}>
										<CheckboxField
											field="targetCleverMap.showQuickExport"
											label="Export"
											defaultValue={true}
										/>
									</Box>
									<Box sx={{ flex: '1 1 0%' }}>
										<CheckboxField
											field="targetCleverMap.showViewSwitch"
											label="View switch"
											defaultValue={false}
										/>
									</Box>
								</Box>
								<Typography variant={'subtitle2'} component={'h3'}>Menu controls visibility</Typography>
								<Box display={'flex'}>
									<Box sx={{ flex: '1 1 0%' }}>
										<CheckboxField
											field="targetCleverMap.showProjectControls"
											label="Project list controls"
											defaultValue={false}
										/>
									</Box>
								</Box>
								<Typography variant={'subtitle2'} component={'h3'}>Theme</Typography>
								<TextField
									field="targetCleverMap.themePrimaryColor"
									label="Primary color"
								/>
								<TextField
									field="targetCleverMap.themeLogoUrl"
									label="Logo url"
								/>
								<ContemberBlockEditor
									contentField="json"
									referencesField="references"
									referenceDiscriminationField="type"
									field="content.parts"
									label="Content"
									size="default"
									sortableBy="order"
									blockButtons={[
										{
											discriminateBy: 'image',
											blueprintIcon: 'media' as const,
											title: 'Image',
										},
										{
											discriminateBy: 'cleverMapForm',
											blueprintIcon: 'form' as const,
											title: 'CleverMaps filters',
										},
									]}
									inlineButtons={inlineButtons}
									augmentEditorBuiltins={(editor) => {
										withAnchorsAsReference(editor, {
											elementType: 'link',
											updateReference: (url, getAccessor) => {
												getAccessor().getField('target.type').updateValue('url')
												getAccessor().getField('target.url').updateValue(url)
											},
										})
										editor.registerElement({
											type: 'link',
											isInline: true,
											render: LinkElement,
										})
										editor.registerElement({
											type: 'bulkFilterCleverMap',
											isInline: true,
											render: BulkFilterCleverMapElement,
										})
										editor.registerElement({
											type: 'linkCleverMap',
											isInline: true,
											render: LinkCleverMapElement,
										})
									}}
								>
									<Block discriminateBy="image" label="Image">
										<ImageUploadField
											label="Image"
											baseEntity="image"
											urlField="url"
											widthField="width"
											heightField="height"
											fileSizeField="size"
											fileTypeField="type"
										>
											<TextField field="alt" label="Caption" />
										</ImageUploadField>
									</Block>
									<Block discriminateBy="cleverMapForm" label="CleverMaps filters">
										<FormBuilder preConnect />
									</Block>
								</ContemberBlockEditor>
							</If>
						</BulkFilterProvider>
					</HasOne>
				</Block>
				<Block discriminateBy="image" label="Image">
					<ImageUploadField
						label="Image"
						baseEntity="image"
						urlField="url"
						widthField="width"
						heightField="height"
						fileSizeField="size"
						fileTypeField="type"
					>
						<TextField field="alt" label="Caption" />
					</ImageUploadField>
				</Block>
				<Block discriminateBy="cleverMapForm" label="CleverMaps filters">
					<FormBuilder />
				</Block>
			</ContemberBlockEditor>
		</ContentProvider>
	),
	'BlockEditor',
)

export const BulkFilterContext = React.createContext<EntityAccessor | null>(null)
export const BulkFilterProvider = Component(
	({ children }: { children: React.ReactNode }) => {
		const entity = useEntity('targetCleverMap')

		return <BulkFilterContext.Provider value={entity}>{children}</BulkFilterContext.Provider>
	},
	({ children }) => <>{children}</>,
)

export const ContentContext = React.createContext<EntityAccessor | null>(null)
export const ContentProvider = Component(
	({ children }: { children: React.ReactNode }) => {
		const entity = useEntity('content')

		return <ContentContext.Provider value={entity}>{children}</ContentContext.Provider>
	},
	({ children }) => (
		<>
			{children}
			<HasOne field="content">
				<HasMany field="parts">
					<HasMany field="references">
						<HasOne field="stack">
							<HasOne field="targetCleverMap">
								<HasMany field="forms" />
							</HasOne>
						</HasOne>
					</HasMany>
				</HasMany>
			</HasOne>
		</>
	),
)
