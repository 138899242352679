import * as React from 'react'
import { MetaDataViewsResponse } from '../../clevermaps-js-sdk'
import { useCleverMapsGetEndpoints } from '../useCleverMapsGetEndpoints'

export const useCleverMapsViewsMetadata = (projectId: string, viewNames: (string | null | undefined)[]) => {
	const urls = React.useMemo(() => viewNames.flatMap(it => it ? [`/rest/projects/${projectId}/md/views?name=${it}`] : []), [projectId, viewNames])
	const { response, error } = useCleverMapsGetEndpoints<MetaDataViewsResponse>(urls)
	const [myResponse, setMyResponse] = React.useState<MetaDataViewsResponse | null>(null)

	if (error) {
		throw new Error(String(error))
	}

	React.useEffect(
		() => {
			// TODO: we don't want to merge metadata in the future, we should just return multiple responses
			if (Array.isArray(response)) {
				const neResponse = response.reduce(
					(previousValue, currentValue) => {
						const mergedFilterGroup = [...currentValue?.content?.filterGroup ?? [], ...previousValue?.content?.filterGroup ?? []]
						const uniqueFilterGroup = new Set(mergedFilterGroup.map((it) => JSON.stringify(it)))

						return ({
							...previousValue,
							content: {
								...previousValue?.content,
								filterGroup: Array.from(uniqueFilterGroup).map((it) => JSON.parse(it))
							}
						})
					}, {} as MetaDataViewsResponse
				)

				setMyResponse(neResponse)
			}
		},
		[response],
	)

	return myResponse
}
