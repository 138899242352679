import { LoginCallback } from '@okta/okta-react';
import React from 'react';

interface MemoizedLoginCallback {
    errorComponent?: React.ComponentType<{
        error: Error;
    }>;
    loadingElement?: React.ReactElement;
}

export const MemoizedLoginCallback = React.memo((props: MemoizedLoginCallback) => (<LoginCallback {...props}/>));

MemoizedLoginCallback.displayName = 'MemoizedLoginCallback'
