// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import {EntityAccessor, Link, RichTextRenderer as ContemberRichTextRenderer} from '@contember/admin'
import {CleverMapsRenderer} from '../../CleverMapsRenderer'
import {richTextBlockParser} from '../richTextBlockParser'
import {CleverMapForm} from './CleverMapForm'
import {CleverMapBulkFilter} from './CleverMapBulkFilter'
import {CleverMapLink} from './CleverMapLink'
import transformEntityToFrameOptions from '../../../utils/transformEntityToFrameOptions';
import styled from '@emotion/styled'

export const linkColor = '#0083FF'

const StoryLink = styled(Link)({
	color: linkColor
})

const ExternalLink = styled('a')({
	color: linkColor
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CleverMap = ({ reference, projectId }: { reference: any; projectId: string }) => {
	type LayoutProps = 'textRight' | 'textLeft' | 'textBelow' | 'textAbove'
	const layout = reference.stack.getField('layout').value as LayoutProps
	const layoutCSS = {
		textRight: 'flex-row',
		textLeft: 'flex-row-reverse',
		textBelow: 'flex-col',
		textAbove: 'flex-col-reverse',
	}

	const frameOptions = transformEntityToFrameOptions(reference.stack, 'targetCleverMap');

	return (
		<div className="my-6">
			<div className={`md:flex ${layoutCSS[layout]} gap-5`}>
				<div className="flex-1 relative">
					<CleverMapsRenderer
						projectId={projectId}
						cleverMapId={reference.stack.getField('targetCleverMap.id').value}
						viewName={reference.stack.getField('targetCleverMap.viewName').value}
						shareId={reference.stack.getField('targetCleverMap.shareId').value}
						storySettingsFrameOptions={frameOptions}
						height="90vh"
						position="sticky"
					/>
				</div>
				<div className={`prose max-w-none mx-auto md:px-6 ${!['textBelow', 'textAbove'].includes(layout) ? 'md:w-1/3' : ''}`}>
					<ContemberRichTextRenderer
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						renderElement={({ fallback, element, children, reference }: any) => {
							if (element.type === 'link') {
								if (reference?.target.getField('type').value === 'chapter') {
									return <StoryLink to={`viewChapter(chapter: '${reference.target.getEntity('chapter').id}')`}>{children}</StoryLink>
								}
								if (reference?.target.getField('type').value === 'story') {
									return <StoryLink to={`viewStory(story: '${reference.target.getEntity('story').id}')`}>{children}</StoryLink>
								}

								return <ExternalLink href={reference.target.getField('url').value} target="_blank" rel="noreferrer">{children}</ExternalLink>
							} else if (element.type === 'bulkFilterCleverMap') {
								return <CleverMapBulkFilter reference={reference}>{children}</CleverMapBulkFilter>
							} else if (element.type === 'linkCleverMap') {
								return <CleverMapLink reference={reference}>{children}</CleverMapLink>
							}

							return fallback
						}}
						blocks={Array.from(reference.stack.getEntityList('content.parts')).map((part) =>
							richTextBlockParser(part as EntityAccessor, { isInside: true }),
						)}
						sourceField="json"
						referenceRenderers={{
							cleverMapForm: ({ reference }) => <CleverMapForm reference={reference}/>,
							image: ({ reference }) => (
								<div className="text-center">
									<div className="inline-block">
										<img
											alt=''
											src={reference.image.getField('url').value}
											width={reference.image.getField('width').value}
											height={reference.image.getField('height').value}
											className="my-6 mx-auto max-w-full !h-auto"
										/>
										{reference.image.getField('alt').value && (
											<p className="text-left -mt-6">
												<em>{reference.image.getField('alt').value}</em>
											</p>
										)}
									</div>
								</div>
							),
						}}
					/>
				</div>
			</div>
		</div>
	)
}
