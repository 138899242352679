import { FieldValue } from '@contember/admin'
import { CleverMapsSDKIframe } from '../components/cleverMapsSDK'

export const setIFrameFilterValue = (iframes:  Array<CleverMapsSDKIframe>,cleverMapIds: Array<FieldValue>, filterId: string, filterValue: null | string | number | Array<string | number> | [number, number]) => {
    iframes
        .filter((iframe) => cleverMapIds.includes(iframe.cleverMapId))
        .forEach((iframe) => {
            if (filterValue !== null) {
                iframe.iframe.message.setFilter(filterId, Array.isArray(filterValue) ? { values: filterValue } : { value: filterValue })
            }
        })
}

export const resetIframeFilters = (iframes:  Array<CleverMapsSDKIframe>, cleverMapIds: Array<FieldValue>,  filterId: string, resetFilterId: (value: null) => void, resetFilterValue: (value: null) => void) => {
    iframes
                .filter((iframe) => cleverMapIds.includes(iframe.cleverMapId))
                .forEach((iframe) => {
                    iframe.iframe.message.removeFilter(filterId)
                })
                resetFilterValue(null)
                resetFilterId(null)
}
