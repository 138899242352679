import { Button, Collapsible, Component, Field, HasMany, HasOne, Repeater, Stack, useEntityList } from '@contember/admin'
import * as React from 'react'
import { ProjectIdContext } from './ProjectIdContext'
import { FilterField } from './FilterField'
import { useCleverMapsListDatasets } from '../hooks/cleverMaps/useCleverMapsListDatasets'
import { useCleverMapsProject } from '../hooks/cleverMaps/useCleverMapsProject'
import { useCleverMapsViewsMetadata } from '../hooks/cleverMaps/useCleverMapsViewsMetadata'
import { CleverMapsSelectField } from './CleverMapsSelectField'
import { CleverMapsMultiSelectField } from './CleverMapsMultiSelectField'
import filterGroupsToPropertyNames from '../utils/filterGroupsToPropertyNames'

export type FormBuilderProps = {
	preConnect?: boolean
}

export const FormBuilder = Component<FormBuilderProps>(
	({ preConnect }) => {
		const projectId = React.useContext(ProjectIdContext)
		const cleverMaps = useEntityList('form.cleverMaps')
		const viewNames = React.useMemo(
			() => Array.from(cleverMaps).map((accessor) => accessor.getField<string>('viewName').value),
			[cleverMaps, cleverMaps.length],
		)
		const metaData = useCleverMapsViewsMetadata(projectId, viewNames)
		const project = useCleverMapsProject({ projectId })
		const datasets = useCleverMapsListDatasets({ projectId })

		const [collapsibleState, setCollapsibleState] = React.useState(false)

		const filterGroups = metaData?.content?.filterGroup
		const propertyNames = filterGroupsToPropertyNames(filterGroups, datasets)

		return (
			<>
				<Stack direction="vertical" gap="large">
					{preConnect ? <CleverMapsSelectField /> : <CleverMapsMultiSelectField />}
					<Button onClick={() => setCollapsibleState(!collapsibleState)}>{collapsibleState ? 'Close' : 'Open'} form builder</Button>
				</Stack>
				<HasOne
					field="form"
					onInitialize={(getAccessor) => {
						if (!getAccessor().existsOnServer && getAccessor().getField('id').value === null) {
							getAccessor().getField('id').asUuid.setToUuid()
						}
					}}
				>
					<Collapsible expanded={collapsibleState}>
						<Stack direction="vertical">
							<Repeater field="fields" label="Filters" sortableBy="order" addButtonText="Add field" initialEntityCount={0}>
								{project && propertyNames && filterGroups && (
									<FilterField propertyNames={propertyNames} filterGroups={filterGroups} project={project} />
								)}
							</Repeater>
						</Stack>
					</Collapsible>
				</HasOne>
			</>
		)
	},
	({ preConnect }) => (
		<>
			{preConnect ? <CleverMapsSelectField /> : <CleverMapsMultiSelectField />}
			<HasOne field="form">
				<HasMany field="cleverMaps">
					<Field field="viewName" />
					<Field field="shareId" />
					<Field field="name" />
					<HasMany field="forms" />
				</HasMany>
				<HasMany field="fields">
					<Field field="order" />
					<Field field="type" />
					<Field field="originType" />
					<Field field="label" />
					<Field field="name" />
					<HasOne field="options">
						<Field field="min" />
						<Field field="max" />
						<Field field="step" />
					</HasOne>
					<HasMany field="values" orderBy="order">
						<Field field="label" />
						<Field field="value" />
						<Field field="order" />
					</HasMany>
				</HasMany>
			</HasOne>
		</>
	),
	'FormBuilder',
)
