import { Component, Field, useField } from '@contember/admin'
import * as React from 'react'

export const ProjectIdContext = React.createContext<string>('')
export const ProjectIdProvider = Component<{ children: React.ReactNode, field: string }>(
	({ children, field }) => {
		const projectId = useField<string>(field).value
		return <ProjectIdContext.Provider value={projectId!}>{children}</ProjectIdContext.Provider>
	},
	({ children, field }) => (
		<>
			<Field field={field} />
			{children}
		</>
	),
	'ProjectIdProvider',
)
