import { createBindingLinkParametersResolver, EntityAccessor, EntityId, IncompleteRequestState, PageRequest, parseLinkTarget, PersistSuccessOptions, RoutingLinkTarget, useEnvironment, useRoutingLinkFactory } from '@contember/admin'
import { useMemo } from 'react'

export type RedirectOnSuccessHandler = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	currentState: PageRequest<any>,
	persistedId: EntityId,
	entity: EntityAccessor,
	options: PersistSuccessOptions,
) => IncompleteRequestState

export type RedirectOnSuccessTarget = string | IncompleteRequestState | RedirectOnSuccessHandler

export const useEntityRedirectOnPersistSuccess = (target: RedirectOnSuccessTarget | undefined) => {
	const linkFactory = useRoutingLinkFactory()
	const env = useEnvironment()

	return useMemo<EntityAccessor.PersistSuccessHandler | undefined>(() => {
		if (!target) {
			return undefined
		}

		return (getAccessor, options) => {
			if (options.successType === 'nothingToPersist') {
				return
			}

			const entity = getAccessor()
			const linkTarget: RoutingLinkTarget = typeof target === 'function'
				? request => target(request!, entity.id, entity, options)
				: target

			const parsedTarget = parseLinkTarget(linkTarget, env)
			const parameters = {}
			const parametersResolver = createBindingLinkParametersResolver(entity)
			const link = linkFactory(parsedTarget, parameters, parametersResolver)

			link.navigate()
		}
	}, [env, linkFactory, target])
}
