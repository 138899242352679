// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import {
	DataBindingProvider,
	EntityListSubTree,
	EntitySubTree,
	Field,
	HasMany,
	HasOne,
	PersistButton,
	StaticRender,
	TextField,
	useOnPersistSuccess,
} from '@contember/admin'

import { Layout } from '../components/Layout'
import { Header } from '../components/layout/Header'
import { ContentCopyHandler, EntityCopier } from '../components/Copier'
import { ContentFields } from '../components/view/staticRenderers/ContentFields'
import { DataBindingStateRenderer } from '../components/DataBindingStateRenderer'
import UserCanEditRouteCheck from '../components/UserCanEditRouteCheck'

const StoryDuplicate = () => {
	return (
		<Layout>
			<DataBindingProvider stateComponent={DataBindingStateRenderer}>
				<EntitySubTree
					entity="Story(id = $id)"
					onPersistSuccess={useOnPersistSuccess({
						redirectOnSuccess: (curr, ids, entity, options) => {
							const createdEntity = Array.from(options.getEntityListSubTree('stories'))[0]
							return { pageName: 'storyEdit', parameters: { id: createdEntity.id } }
						},
					})}
					onInitialize={async (getEntity, options) => {
						options.getEntityListSubTree('stories').createNewEntity((newEntity) => {
							const copier = new EntityCopier([
								new ContentCopyHandler('ContentPart', 'json', 'references'),
								{
									copyColumn: ({ source, marker, target }) => {
										if (marker.fieldName === 'title') {
											const newTitle = `Copy of ${source.getField<string>('title').value}`
											target.getField('title').updateValue(newTitle)
											return true
										}

										return false
									},
								},
							])

							copier.copy(getEntity(), newEntity())
						})
					}}
				>
					<UserCanEditRouteCheck redirectRoute='storyList'>
					<Header actions={<></>} />
					<div className="container max-w-2xl mx-auto">
						<div className="mt-10 p-8 rounded-lg shadow-sm border border-slate-100">
							<h1 className="font-bold text-2xl text-black">Duplicate story</h1>
							<EntityListSubTree entities="Story" alias="stories" limit={0}>
								<StaticRender>
									<Field field="title" />
									<Field field="useStyle" />
									<Field field="style" />
									<Field field="headerHeadline" />
									<Field field="headerSubheading" />
									<HasOne field="headerImage" />
									<Field field="hasHeader" />
									<ContentFields />
									<HasMany field="chapters">
										<Field field="order" />
										<Field field="title" />
										<ContentFields />
									</HasMany>
									<Field field="cleverMapsId" />
								</StaticRender>
								<div className="flex mt-8">
									<div className="flex-grow w-full">
										<div className="mb-8">
											<TextField label="New title of your story" field="title" />
										</div>
									</div>
								</div>
							</EntityListSubTree>
							<PersistButton
								size="large"
								className="bg-slate-100  w-full text-center button-blue"
								labelSaved="Duplicated story"
								labelSave="Duplicate story"
							/>
						</div>
					</div>
					</UserCanEditRouteCheck>
					<StaticRender>
						<Field field="title" />
						<Field field="useStyle" />
						<Field field="style" />
						<Field field="headerHeadline" />
						<Field field="headerSubheading" />
						<HasOne field="headerImage" />
						<Field field="hasHeader" />
						<ContentFields />
						<HasMany field="chapters">
							<Field field="order" />
							<Field field="title" />
							<ContentFields />
						</HasMany>
						<Field field="cleverMapsId" />
					</StaticRender>
				</EntitySubTree>
			</DataBindingProvider>
		</Layout>
	)
}

export default StoryDuplicate;
