import {EntityAccessor} from '@contember/admin';
import {FrameOptions} from '../components/CleverMapsRenderer';

const transformEntityToFrameOptions = (entity: EntityAccessor, prefix: string): FrameOptions => {
    const prefixFieldName = (fieldName: string) => prefix.length > 0 ? [prefix, fieldName].join('.') : fieldName;

    const menu = entity.getField<boolean>(prefixFieldName('showProjectControls')).value;
    const viewSwitch = entity.getField<boolean>(prefixFieldName('showViewSwitch')).value;
    const measure = entity.getField<boolean>(prefixFieldName('showMeasure')).value;
    const compare = entity.getField<boolean>(prefixFieldName('showCompare')).value;
    const search = entity.getField<boolean>(prefixFieldName('showSearch')).value;
    const defaultExpanded = entity.getField<boolean>(prefixFieldName('infoBoxDefaultExpanded')).value;
    const share = entity.getField<boolean>(prefixFieldName('showQuickShare')).value;
    const quickExport = entity.getField<boolean>(prefixFieldName('showQuickExport')).value;
    const afterClick = entity.getField<boolean>(prefixFieldName('interactivityAfterClick')).value;
    const fullScreenButton = entity.getField<boolean>(prefixFieldName('fullScreenButton')).value;
    const logoSrc = entity.getField<string|null>(prefixFieldName('themeLogoUrl')).value ?? undefined;
    const colorPrimary = entity.getField<string|null>(prefixFieldName('themePrimaryColor')).value ?? undefined;
    const theme = !logoSrc && !colorPrimary ? undefined : { logoSrc, colorPrimary }

    return {
        componentSettings: {
            controls: {
                menu
            },
            tools: {
                measure,
                compare,
                search,
            },
            infoBox: {
                defaultExpanded,
                share,
                export: quickExport,
                viewSwitch,
            },
        },
        interactivity: {
            afterClick
        },
        fullScreenButton: {
            enabled: fullScreenButton
        },
        theme
    };
};

export default transformEntityToFrameOptions;
