// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import { Field, StaticRender } from '@contember/admin'
import { EditScope } from '../components/EditScope'
import { ChapterForm } from '../components/forms/chapterForm'
import { Layout } from '../components/Layout'
import { Header } from '../components/layout/Header'



const ChapterEdit = () => (
	<Layout>
		<EditScope entity="Chapter(id = $id)">
			<Header backButton={{ to: 'storyEdit(id: $entity.story.id)', text: 'Back to story' }} showViewChapter />
			<div className="container mx-auto mb-8">
				<ChapterForm />
			</div>
			<StaticRender>
				<Field field="story.id" />
			</StaticRender>
		</EditScope>
	</Layout>
)

export default ChapterEdit;
