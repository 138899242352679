// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import {Component, Field, HasMany, HasOne} from '@contember/admin'

export const FormFields = Component(
	() => (
		<HasOne field="form">
			<HasMany field="cleverMaps">
				<Field field="viewName" />
				<Field field="shareId" />
				<Field field="showMeasure" />
				<Field field="showCompare" />
				<Field field="showSearch" />
				<Field field="infoBoxDefaultExpanded" />
				<Field field="showProjectControls" />
				<Field field="showViewSwitch" />
				<Field field="showQuickShare" />
				<Field field="showQuickExport" />
				<Field field="fullScreenButton" />
				<Field field="interactivityAfterClick" />
				<Field field="themeLogoUrl" />
				<Field field="themePrimaryColor" />
				<Field field="name" />
				<HasMany field="forms" />
			</HasMany>
			<HasMany field="fields">
				<Field field="type" />
				<Field field="originType" />
				<Field field="order" />
				<Field field="label" />
				<Field field="name" />
				<HasOne field="options">
					<Field field="min" />
					<Field field="max" />
					<Field field="step" />
				</HasOne>
				<HasMany field="values">
					<Field field="label" />
					<Field field="value" />
					<Field field="order" />
				</HasMany>
			</HasMany>
		</HasOne>
	),
	'FormFields',
)
