// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import { Box, DataBindingProvider, DataGrid, GenericCell, Icon, Link, LinkButton, Stack, TextCell } from '@contember/admin'
import { Announcement } from '../components/Announcement'
import { Header } from '../components/Header'
import { Layout } from '../components/Layout'
import { EyeIcon } from '@heroicons/react/24/outline'
import { ArchiveEntityButton } from '../components/ArchiveEntityButton'
import { DataBindingStateRenderer } from '../components/DataBindingStateRenderer'
import StoryListEditableCondition from '../components/StoryListEditableCondition'
import styled from '@emotion/styled'

const ButtonContainer = styled(Box)({
	width: '80px',
	padding: '0',
	border: 'none'
})

const ArchiveButtonContainer = styled(Box)({
	width: '36px',
	padding: '0',
	border: 'none'
})

const StoryList = () => (
	<DataBindingProvider stateComponent={DataBindingStateRenderer} refreshOnPersist>
		<Layout>
			<Header />
			<Announcement />
			<div className='max-w-7xl w-full mx-auto mt-10 px-2 sm:px-6 lg:px-8'>
				<DataGrid entities='Story[deletedAt = null]' itemsPerPage={25}>
					<TextCell field='title' header='Title' />
					<GenericCell shrunk canBeHidden={false}>
						<Stack direction='horizontal' className='items-center' gap='large'>
							<Link target='_blank' to='viewStory(story: $entity.id)'>
								<div className='flex gap-2 items-center'>
									<EyeIcon className='w-4 h-4' />
									View story
								</div>
							</Link>
							<StoryListEditableCondition skeletonWidth={80} skeletonVariant='rounded'>
								<ButtonContainer>
									<LinkButton to='storyEdit(id: $entity.id)'>Edit story</LinkButton>
								</ButtonContainer>
							</StoryListEditableCondition>
							<StoryListEditableCondition skeletonWidth={80} skeletonVariant='rounded'>
								<ButtonContainer>
									<LinkButton to='storyDuplicate(id: $entity.id)' distinction='seamless'>
										Duplicate
									</LinkButton>
								</ButtonContainer>
							</StoryListEditableCondition>
						</Stack>
					</GenericCell>
					<GenericCell shrunk canBeHidden={false}>
						<StoryListEditableCondition skeletonWidth={36} skeletonVariant='circular'>
							<ArchiveButtonContainer>
								<ArchiveEntityButton field='deletedAt'>
									<Icon blueprintIcon='delete' />
								</ArchiveEntityButton>
							</ArchiveButtonContainer>
						</StoryListEditableCondition>
					</GenericCell>
				</DataGrid>
			</div>
		</Layout>
	</DataBindingProvider>
)

export default StoryList;