import * as React from 'react'
import { useApiBaseUrl, useSessionToken } from '@contember/admin'

export const useCleverMapsGetEndpoint = <T,>(path: string | null, retryOnNotFound: boolean = false): { response: T | null, error: number | null } => {
	const url = useApiBaseUrl()
	const token = useSessionToken()
	const [payload, setPayload] = React.useState<T | null>(null)
	const [error, setError] = React.useState<number | null>(null)

	const isMountedRef = React.useRef(true)

	React.useEffect(() => () => {
		isMountedRef.current = false
	}, [])

	React.useEffect(
		() => {
			if (path) {
				const sendRequest = async (): Promise<void> => {
					const response = await fetch(`${url}${path}`, {
						headers: {
							'Authorization': `Bearer ${token}`,
							'Accept': 'application/json',
						},
					})

					if (!isMountedRef.current) {
						return
					}

					if (response.status === 404 && retryOnNotFound) {
						setTimeout(sendRequest, 5000)
						return
					}

					if (!response.ok) {
						setError(response.status)
						return
					}

					const payload = await response.json()

					if (!isMountedRef.current) {
						return
					}

					setPayload(payload)
				}
				sendRequest()
			}
		},
		[url, token, path],
	)

	return { response: payload, error }
}
