import * as React from 'react'
import { Component, Field, FieldContainer, FieldErrors, FieldView } from '@contember/admin'
import { useCleverMapsGetEndpoint } from '../hooks/useCleverMapsGetEndpoint'
import Select from 'react-select'

const useCleverMapsProjects = () => {
	const { response, error } = useCleverMapsGetEndpoint<{ content: Array<Project>}>('/rest/projects?size=1000&page=0&expand=membership')
	if (error) {
		throw new Error(String(error))
	}
	return response
}

type Project = {
	id: string;
	title: string;
	membership: {
		role: string;
	}
}

type SelectValue = {
	value: string;
	label: string;
}

const ProjectsContext = React.createContext<Project[]>([])
export const ProjectsProvider = ({ children }: { children: React.ReactNode }) => {
	const projects = useCleverMapsProjects()

	if (!projects) {
		return null
	}

	return <ProjectsContext.Provider value={projects.content}>{children}</ProjectsContext.Provider>
}

export const CleverMapsIdSelect = Component<{ field: string; label: React.ReactNode }>(
	({ field, label }) => {
		const projects: SelectValue[] = (useCleverMapsProjects()?.content ?? [])
			.filter((project) => ['ADMIN', 'VIEW_CREATOR', 'EDITOR'].includes(project.membership.role))
			.map(({ id, title }: Project) => ({ value: id, label: title }))
		const [selectedProject, setSelectedProject] = React.useState<SelectValue | undefined>(undefined)

		return (
			<FieldView<string>
				field={field}
				render={(accessor) => {
					const errors = accessor.errors?.errors as FieldErrors

					return (
						<FieldContainer label={label} errors={errors}>
							<Select<SelectValue>
								value={selectedProject}
								onChange={(value) => {
									accessor.updateValue(value?.value ?? null)

									if (!value) return
									setSelectedProject(value)
								}}
								options={projects}
							/>
						</FieldContainer>
					)
				}}
			/>
		)
	},
	({ field }) => {
		return (
			<>
				<Field field={field} />
			</>
		)
	},
	'CleverMapsIdSelect',
)
