import { Component, FieldContainer, HiddenField, If, Repeater, SelectField, Stack, TextField, useEntityList, useField } from '@contember/admin'
import * as React from 'react'
import Select from 'react-select'

import { FilterGroup, GetProjectResponse } from '../clevermaps-js-sdk'
import { useCleverMapsPropertyValues } from '../hooks/cleverMaps/useCleverMapsPropertyValues'
import { useCleverMapsFilterOptions } from '../hooks/cleverMaps/useCleverMapsFilterOptions'

type SelectOption = {
	value: string
	label: string
}

export type FilterFieldProps = {
	propertyNames: SelectOption[]
	filterGroups: FilterGroup[]
	project: GetProjectResponse
}

const typeParser = {
	globalDate: 'todo',
	multiSelect: 'multiSelectField',
	singleSelect: 'singleSelectField',
	radio: 'radioField',
	histogram: 'rangeField',
}

export const FilterField = Component<FilterFieldProps>(({ propertyNames, filterGroups, project }) => {
	const [isFieldReplacementEnabled, setIsFieldReplacementEnabled] = React.useState(false)

	const values = useEntityList({ field: 'values', orderBy: 'order' })
	const typeField = useField('type')
	const originTypeField = useField('originType')
	const labelField = useField<string>('label')
	const datasetNameField = useField<string | null>('name')
	const minField = useField<string | null>('options.min')
	const maxField = useField<string | null>('options.max')
	const stepField = useField<number | null>('options.step')
	const datasetName = datasetNameField.value
	const filterGroup = filterGroups?.find((it) => it.property === datasetName)
	const filterGroupType = filterGroup?.type
	const propertyValues = useCleverMapsPropertyValues({
		url: project.services.dwh,
		datasetName: datasetName ?? null,
		filterGroupType: filterGroupType ?? 'multiSelect',
		orderBy: filterGroup?.orderBy,
	})
	const options = useCleverMapsFilterOptions(filterGroupType)

	React.useEffect(() => {
		// was datasetNameField changed? if not stop it
		if (!isFieldReplacementEnabled) {
			return
		}

		setIsFieldReplacementEnabled(false)
		const propertyValuesContent = propertyValues?.content

		values.deleteAll()
		if (filterGroupType) {
			typeField.updateValue(typeParser[filterGroupType])
			originTypeField.updateValue(typeParser[filterGroupType])

			if (Array.isArray(propertyValuesContent)) {
				if (filterGroupType === 'histogram') {
					const min = propertyValuesContent
						.filter((it) => it.min !== null)
						?.map((it) => it.min)
						.sort((a, b) => a - b)[0]
					const max = propertyValuesContent
						.filter((it) => it.max !== null)
						?.map((it) => it.max)
						.sort((a, b) => a - b)
						.reverse()[0]

					minField.updateValue(min)
					maxField.updateValue(max)
					const defaultDecimalPlaces = 1
					const step = defaultDecimalPlaces / Math.pow(10, Math.max(filterGroup.format?.fraction ?? defaultDecimalPlaces, 0))
					stepField.updateValue(step)

					return
				} else if (filterGroupType === 'globalDate') {
					return
				} else if (propertyValuesContent) {
					propertyValuesContent.forEach((value, index) => {
						const newEntity = values.createNewEntity()
						const currentNewValue = Array.from(values.getAccessor()).find((it) => it.id === newEntity.value)
						let label = value
						if (typeof value === 'number') {
							label = String(value)
							value = String(`number_${value}`) // fix when number is returned
						}
						if (label === '') {
							label = 'Not provided'
						}
						currentNewValue?.getField('value').updateValue(value === null ? 'NULL' : value) // if for NULL values
						currentNewValue?.getField('label').updateValue(label ? label : 'Not defined') // if for NULL values
						currentNewValue?.getField('order').updateValue(index)
					})

					return
				}
			}
		}
	}, [propertyValues]) // as change dependency watch for new async propertyValues setting

	return (
		<>
			<Stack direction="horizontal" justify="stretch">
				<FieldContainer label="Property">
					<Select<SelectOption>
						options={propertyNames ?? []}
						value={propertyNames.find((item) => item.value === datasetName)}
						onChange={({ value, label }) => {
							if (value) {
								datasetNameField.updateValue(value)
								labelField.updateValue(label ?? '')
								setIsFieldReplacementEnabled(true)
							}
						}}
						isSearchable
					/>
				</FieldContainer>
				<TextField field="label" label="Label" />
			</Stack>
			<If condition="[name != null]">
				<HiddenField field="originType" label="Origin API type" />
				<SelectField
					field="type"
					label="Type"
					options={options}
				/>
				<Repeater field="values" label="Values" orderBy="order" enableRemoving={false}>
					<Stack direction="horizontal" justify="stretch">
						<TextField field="label" label="Title" />
						<TextField field="value" label="Value" />
						<HiddenField field="order" label="Order" />
					</Stack>
				</Repeater>
			</If>
		</>
	)
}, 'FilterField')
