import * as React from 'react'
import { useApiBaseUrl, useSessionToken } from '@contember/admin'

export const useCleverMapsPostEndpoint = (path: string | null, body?: Record<string, unknown>, headers?: Record<string, unknown>): { response: Headers | null, error: number | null } => {
	const url = useApiBaseUrl()
	const token = useSessionToken()
	const [payload, setPayload] = React.useState<Headers | null>(null)
	const [error, setError] = React.useState<number | null>(null)

	const isMountedRef = React.useRef(true)

	React.useEffect(() => () => {
		isMountedRef.current = false
	}, [])

	React.useEffect(
		() => {
			if (path) {
				(async () => {
					const response = await fetch(`${url}${path}`, {
						headers: {
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${token}`,
							'Accept': 'application/json',
							...headers
						},
						method: 'POST',
						body: body ? JSON.stringify(body) : undefined
					})

					if (!isMountedRef.current) {
						return
					}

					if (!response.ok) {
						setError(response.status)
						return
					}

					setPayload(response.headers)
				})()
			}
		},
		[url, token, path],
	)

	return { response: payload, error }
}
