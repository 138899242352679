// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import { Component, DataBindingProvider, EntitySubTree, Layout, Link, ToasterContext, useEntitySubTree, useRedirect } from '@contember/admin'
import { Header } from '../components/view/Header'
import { Hero } from '../components/view/Hero'
import { RichTextRenderer } from '../components/view/RichTextRenderer'
import { StoryFields } from '../components/view/staticRenderers/StoryFields'
import { DataBindingStateRenderer } from '../components/DataBindingStateRenderer'
import BrokenLinkMessage from '../components/BrokenLinkMessage'

const ViewStory = Component(
	() => {
		const story = useEntitySubTree('story')
		const chapters = story.getEntityList({ field: 'chapters', orderBy: 'order' })
		const style = story.getField<string>('style').value
		const isStyled = story.getField<boolean>('useStyle').value
		const isMobileHeader = story.getField<boolean>('useMobileHeader').value
		const projectId = story.getField<string>('cleverMapsId').value
		const { showToast} = React.useContext(ToasterContext)
		const redirect = useRedirect()

		React.useEffect(() => {
			if (!projectId) {
				redirect('storyList')
				showToast({ type:'error', message: <BrokenLinkMessage />})
			}
		}, [projectId, redirect, showToast])

		return (
			<>
				{isStyled && <style>{style}</style>}
				<div className="custom-layout">
					<Header title={story.getField<string>('title').value!} chapters={chapters} storyId={story.id} isMobileHeader={isMobileHeader} />
					<Hero
						imageUrl={story.getField<string>('headerImage.url').value!}
						headline={story.getField<string>('headerHeadline').value!}
						subheading={story.getField<string>('headerSubheading').value!}
					/>

					<div className="bg-gray-100 pb-10">
						<div className="py-16 px-4 sm:px-6 lg:px-8 prose max-w-none">
							<RichTextRenderer projectId={projectId} parts={Array.from(story.getEntityList('content.parts'))} />
						</div>
						{Array.from(chapters).length > 0 && (
							<div className="text-center">
								<p className="mb-5 text-gray-500">Continue reading</p>
								<div className="my-2">
									<Link to={`viewChapter(chapter: '${Array.from(chapters)[0]?.id}')`}>
										<span className="button bg-red-500 text-white py-3 px-6 rounded hover:bg-red-600">
											<>{Array.from(chapters)[0]?.getField('title').value}</>
										</span>
									</Link>
								</div>
							</div>
						)}
					</div>
				</div>
			</>
		)
	},
	() => (
		<EntitySubTree entity="Story(id = $story)" alias="story">
			<StoryFields />
		</EntitySubTree>
	),
	'ViewStory',
)

const ViewStoryPage = () => (
	<Layout scheme="light">
		<DataBindingProvider stateComponent={DataBindingStateRenderer}>
			<div className="bg-white">
				<main>
					<div>
						<ViewStory />
					</div>
				</main>
			</div>
		</DataBindingProvider>
	</Layout>
)

export default ViewStoryPage;
