import { EntityAccessor, FieldValue, useEntity } from '@contember/admin'
import * as React from 'react'
import Select, { ActionMeta, MultiValue } from 'react-select'
import { ContentContext } from './BlockEditor'

interface CleverMapsMultiSelectFieldProps {
	mode?: 'link'
	maxMenuHeight?: number
}

interface CleverMapsOption {
	label: FieldValue
	value: EntityAccessor
}

export const CleverMapsMultiSelectField = ({ mode, maxMenuHeight }: CleverMapsMultiSelectFieldProps) => {
	const entity = useEntity(mode === 'link' ? undefined : 'form')
	const cleverMaps = entity?.getEntityList(mode === 'link' ? 'targetCleverMaps' : 'cleverMaps')
	const [menuOpened, setMenuOpened] = React.useState(false)

	const contentEntity = React.useContext(ContentContext)
	const parts = contentEntity.getEntityList('parts')

	// Initialize cleverMapsValues based on cleverMaps
	const cleverMapsDefaultValues = React.useMemo(() => (
		cleverMaps ? Array.from(cleverMaps).map(it => ({ label: it.getField('name').value, value: it })) : []
	), [cleverMaps])

	const [cleverMapsValues, setCleverMapsValues] = React.useState(cleverMapsDefaultValues)
	const [cleverMapsOptions, setCleverMapsOptions] = React.useState<CleverMapsOption[]>()

	React.useEffect(() => {
		const tempArray = Array.from(parts).reduce((acc, part) => {
			if (!part.getField('json').value) return acc

			const referencesArray = Array.from(part.getEntityList('references'))
			const references = referencesArray.filter(
				it => it.getField('type').value === 'cleverMap' &&
					!Array.from(it.getEntityList('stack.targetCleverMap.forms')).find(form => form.id === entity.id),
			)
			return [...acc, ...references]
		}, [])

		const newOptions = tempArray.map(it => ({
			label: it.getField('stack.targetCleverMap.name').value,
			value: it.getEntity('stack.targetCleverMap'),
		}))

		setCleverMapsOptions(newOptions)
	}, [parts, entity, cleverMapsValues, menuOpened])

	const onChange = React.useCallback((newValues: MultiValue<{
		label: FieldValue,
		value: EntityAccessor
	}>, actionMeta: ActionMeta<{ label: FieldValue, value: EntityAccessor }>) => {
		const valuesArray = [...newValues] as { label: FieldValue, value: EntityAccessor }[]
		setCleverMapsValues(valuesArray)
		switch (actionMeta.action) {
			case 'remove-value':
				cleverMaps?.disconnectEntity(actionMeta.removedValue.value)
				break
			case 'select-option':
				cleverMaps?.connectEntity(actionMeta.option.value)
				break
			case 'clear':
				cleverMaps?.disconnectAll()
				break
			default:
				break
		}
	}, [cleverMaps])

	return (
		<Select
			value={cleverMapsValues}
			options={cleverMapsOptions}
			onMenuOpen={() => setMenuOpened(true)}
			onMenuClose={() => setMenuOpened(false)}
			onChange={(newValues, actionMeta) => onChange(newValues, actionMeta)}
			isMulti
			maxMenuHeight={maxMenuHeight}
		/>
	)
}
