import { GetCurrentAccountResponse } from '../../clevermaps-js-sdk'
import { useCleverMapsGetEndpoint } from '../useCleverMapsGetEndpoint'

export const useCleverMapsCurrentAccount = () => {
	const { response, error } = useCleverMapsGetEndpoint<GetCurrentAccountResponse>('/rest/accounts/current')
	if (error) {
		throw new Error(String(error))
	}
	return response
}
