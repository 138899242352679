import { Component, Link } from '@contember/admin'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import Logo from '../components/svgs/Logo'

export const Header = Component(
    () => (
        <header className="bg-clever-bg">
            <nav className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
                <div className="w-full flex items-center justify-between border-b border-blue-500 lg:border-none">
                    <div className="flex items-center">
                        <Link to="index">
                            <span className="sr-only">CleverMaps</span>
                            <Logo />
                        </Link>
                        <div className="hidden ml-10 space-x-8 lg:block">
                        </div>
                    </div>
                    <div className="ml-10 space-x-4">
                        <Link to="storyCreate" className='shadow inline-block bg-white py-2 px-4 border border-transparent rounded-md text-base font-medium text-clever-bg hover:bg-opacity-90'>
                            New story
                        </Link>
                    </div>
                </div>
                <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
                </div>
            </nav>
        </header>
    ),
    'Header',
)
