import { Button, Component, Field, SugarableRelativeSingleField, useField, useMutationState, usePersistWithFeedback } from '@contember/admin'
import * as React from 'react'

export type RecoverEntityButtonProps = {
	children: React.ReactNode
	field: SugarableRelativeSingleField | string
}

export const RecoverEntityButton = Component<RecoverEntityButtonProps>(
	({ children, field }) => {
		const archiveField = useField(field)
		const triggerPersist = usePersistWithFeedback()
		const isMutating = useMutationState()

		const onClick = React.useCallback(
			async () => {
				archiveField.updateValue(null)
				triggerPersist().catch(() => { })
			}, [triggerPersist]
		)

		return <Button intent="primary" onClick={onClick} disabled={isMutating}>{children}</Button>
	},
	({ children, field }) => (
		<>
			<Field field={field} />
			{children}
		</>
	),
	'RecoverEntityButton',
)
