import { Component, EntityAccessor, FieldValue, useEntity } from '@contember/admin'
import * as React from 'react'
import Select from 'react-select'
import { ContentContext } from './BlockEditor'

export const CleverMapLinkSelectField = Component(
	() => {
		let entity: EntityAccessor | undefined = undefined
		let cleverMap: EntityAccessor | undefined = undefined
		entity = useEntity()
		cleverMap = entity.getEntity('targetCleverMap')

		if (!entity || !cleverMap) {
			return null
		}

		const [cleverMapsValue, setCleverMapsValue] = React.useState<{
			label: FieldValue
			value: EntityAccessor
		}>({ label: cleverMap.getField('name').value, value: cleverMap })
		const [cleverMapsOptions, setCleverMapsOptions] = React.useState([])
		const contentEntity = React.useContext(ContentContext)
		const parts = contentEntity.getEntityList('parts')
		const tempArray = []

		React.useEffect(() => {
			for (const part of Array.from(parts)) {
				tempArray.push(...Array.from(part.getEntityList('references')).filter((it) => it.getField('type').value === 'cleverMap'))
			}
			setCleverMapsOptions(
				tempArray.map((it) => ({
					label: it.getField('stack.targetCleverMap.name').value,
					value: it.getEntity('stack.targetCleverMap'),
				})),
			)
		}, [parts, cleverMapsValue])

		const onChange = React.useCallback(
			(value: { label: FieldValue; value: EntityAccessor }) => {
				if (!value) {
					setCleverMapsValue(undefined)
					entity.disconnectEntityAtField('targetCleverMap')
				} else {
					setCleverMapsValue({ label: value.label, value: value.value })
					entity.connectEntityAtField('targetCleverMap', value.value)
				}
			},
			[cleverMap],
		)

		return (
			<>
				<Select value={cleverMapsValue} options={cleverMapsOptions} onChange={(value) => onChange(value)} isSearchable maxMenuHeight={125} />
			</>
		)
	},
	() => <></>,
	'CleverMapLinkSelectField',
)
