const iframeValueTransform = (input: string | number | null): string => {
	if (typeof input === 'number') {
		return String(`number_${input}`)
	}
	if (input === null) {
		return 'NULL';
	}
	return input
}

export default iframeValueTransform
