// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'

type HeroProps = {
	imageUrl: string
	subheading: string
	headline: string
}

export const Hero = ({ imageUrl, subheading, headline }: HeroProps) => (
	<div className="relative hero">
		<div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
		<div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
			<div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
				<div className="absolute inset-0">
					<img className="h-full w-full object-cover" src={imageUrl} alt="" />
					<div className="absolute inset-0 bg-orange-800 mix-blend-multiply" />
				</div>
				<div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
					<h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
						<span className="block text-white">{headline}</span>
						<span className="block text-orange-100">{subheading}</span>
					</h1>
				</div>
			</div>
		</div>
	</div>
)
