import { GetViewsResponse } from '../../clevermaps-js-sdk'
import { useCleverMapsGetEndpoint } from '../useCleverMapsGetEndpoint'

export const useCleverMapsViews = (props: { projectId: string | undefined }) => {
	const { response, error } = useCleverMapsGetEndpoint<GetViewsResponse>(`/rest/projects/${props.projectId}/md/views?size=10000`)
	if (error) {
		throw new Error(String(error))
	}
	return response
}
