// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import { StoryEditForm } from '../components/forms/storyForms'
import { Layout } from '../components/Layout'
import { EditScope } from '../components/EditScope'
import { Header } from '../components/layout/Header'

const StoryEdit = () => (
	<Layout>
		<EditScope entity="Story(id = $id)">
			<Header backButton={{ to: 'storyList', text: 'All stories' }} showViewStory />
			<div className="container mx-auto mb-8">
				<StoryEditForm />
			</div>
		</EditScope>
	</Layout>
)

export default StoryEdit;
