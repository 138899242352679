import {
	Button,
	Component,
	DataBindingProvider,
	EntityListSubTree,
	Field,
	LinkButton,
	Stack,
	useEntity,
	usePersist,
	useRedirect,
} from '@contember/admin'
import { DataBindingStateRenderer } from '../components/DataBindingStateRenderer'
import { Layout } from '../components/Layout'
import { Header } from '../components/layout/Header'

const StoryChapterDelete = () => (
	<Layout>
		<DataBindingProvider stateComponent={DataBindingStateRenderer}>
			<EntityListSubTree entities="Chapter[id=$id]">
				<Header actions={null} />
				<div className="container max-w-2xl mx-auto">
					<div className="mt-10 p-8 rounded-lg shadow-sm border border-slate-100">
						<Stack direction="vertical">
							<div>
								Delete <b>{<Field field="title" />}</b> chapter from <b>{<Field field="story.title" />}?</b>
							</div>
							<DeleteEntity />
						</Stack>
					</div>
				</div>
			</EntityListSubTree>
		</DataBindingProvider>
	</Layout>
)

export default StoryChapterDelete;

const DeleteEntity = Component(
	() => {
		const redirect = useRedirect()
		const persist = usePersist()
		const parentEntity = useEntity()

		return (
			<Stack direction="horizontal">
				<LinkButton to="storyEdit(id: $entity.story.id)">Cancel</LinkButton>
				<Button
					intent="danger"
					onClick={async () => {
						parentEntity.deleteEntity()
						await persist()

						redirect('storyEdit(id: $entity.story.id)')
					}}
				>
					Delete
				</Button>
			</Stack>
		)
	},
	() => <LinkButton to="storyEdit(id: $entity.story.id)">Cancel</LinkButton>,
)
