import generate from 'project-name-generator'
import * as React from 'react'
import { CleverMapsSDKContext } from './cleverMapsSDK'
import { merge } from 'lodash';

type Tools = {
	measure: boolean;
	compare: boolean;
	search: boolean;
}

type InfoBox = {
	defaultExpanded: boolean;
	share: boolean;
	export: boolean;
	viewSwitch: boolean;
}

type Controls = {
	menu: boolean;
}

type Interactivity = {
	afterClick: boolean;
}

type FullScreenButton = {
	enabled: boolean;
}

type Theme = {
	colorPrimary?: string;
	logoSrc?: string;
}

type ComponentSettings = {
	tools: Tools;
	infoBox: InfoBox;
	controls: Controls;
}

export type FrameOptions = {
	componentSettings: ComponentSettings;
	interactivity: Interactivity;
	fullScreenButton: FullScreenButton;
	theme?: Theme;
}

type CleverMapsRendererProps = {
	projectId: string
	viewName: string
	shareId: string | undefined
	cleverMapId: string
	storySettingsFrameOptions: FrameOptions
	height?: string
	position?: 'relative' | 'absolute' | 'fixed' | 'sticky' | 'static'
}

export const CleverMapsRenderer = ({ projectId, viewName, height, position, cleverMapId, shareId, storySettingsFrameOptions }: CleverMapsRendererProps) => {
	const { sdk, setIframes } = React.useContext(CleverMapsSDKContext)
	const currentCleverMapId = cleverMapId ?? generate().dashed
	const url = shareId ? `${projectId}/map/${viewName}?shareId=${shareId}` : `${projectId}/map/${viewName}`
	const frameOptions = merge({}, storySettingsFrameOptions, {
		componentSettings: {
			tools: {
				filters: false
			},
			infoBox: {
				globalFilters: false
			}
		}
	});

	React.useEffect(() => {
		if (viewName && sdk && currentCleverMapId) {
			; (async () => {
				const iframe = sdk.createIframe(url, frameOptions)
				sdk.renderIframe(currentCleverMapId, iframe)
				setIframes((iframes) => [...iframes, { cleverMapId: currentCleverMapId, iframe, projectId, viewName }])
			})()
		}
	}, [viewName, currentCleverMapId, sdk, setIframes])

	return (
		<div style={{ position: position ?? 'relative' }} className="top-3">
			<div id={currentCleverMapId} style={{ height: height ?? '500px' }}></div>
		</div>
	)
}
