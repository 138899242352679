import { EntityAccessor, FieldMarker, generateUuid, HasManyRelationMarker, HasOneRelationMarker, PRIMARY_KEY_NAME } from '@contember/admin'

interface CopyEntityArgs {
	copier: EntityCopier
	source: EntityAccessor
	target: EntityAccessor
}

interface CopyHasOneRelationArgs {
	copier: EntityCopier
	source: EntityAccessor
	target: EntityAccessor
	marker: HasOneRelationMarker
}

interface CopyHasManyRelationArgs {
	copier: EntityCopier
	source: EntityAccessor
	target: EntityAccessor
	marker: HasManyRelationMarker
}

interface CopyColumnArgs {
	copier: EntityCopier
	source: EntityAccessor
	target: EntityAccessor
	marker: FieldMarker
}

interface CopyHandler {
	copy?(args: CopyEntityArgs): boolean

	copyHasOneRelation?(args: CopyHasOneRelationArgs): boolean

	copyHasManyRelation?(args: CopyHasManyRelationArgs): boolean

	copyColumn?(args: CopyColumnArgs): boolean
}

export class ContentCopyHandler implements CopyHandler {
	constructor(private blockEntity: string, private contentField: string, private referencesField: string) {}

	copyColumn({ source, marker }: CopyColumnArgs): boolean {
		return source.name === this.blockEntity && marker.fieldName === this.contentField
	}

	copyHasManyRelation({ source, target, copier, marker }: CopyHasManyRelationArgs): boolean {
		if (source.name !== this.blockEntity || marker.parameters.field !== this.referencesField) {
			return false
		}
		const list = source.getEntityList(marker.parameters)
		const targetList = target.getEntityList(marker.parameters)

		targetList.disconnectAll()
		const referenceMapping = new Map<string, string>()
		for (const subEntity of list) {
			const newId = generateUuid()
			referenceMapping.set(subEntity.id as string, newId)
			targetList.createNewEntity((target) => {
				copier.copy(subEntity, target())
				target().getField(PRIMARY_KEY_NAME).updateValue(newId)
			})
		}
		const jsonRaw = source.getField(this.contentField).value
		if (typeof jsonRaw !== 'string') {
			return true
		}
		const jsonValue = JSON.parse(jsonRaw, (key, value) => {
			if (key === 'referenceId') {
				return referenceMapping.get(value)
			}
			return value
		})
		target.getField(this.contentField).updateValue(JSON.stringify(jsonValue))

		return true
	}
}

export class EntityCopier {
	constructor(private handlers: CopyHandler[] = []) {}

	copy(source: EntityAccessor, target: EntityAccessor) {
		for (const handler of this.handlers) {
			if (handler.copy?.({ copier: this, source, target })) {
				return
			}
		}
		for (const [, marker] of source.getMarker().fields.markers) {
			if (marker instanceof FieldMarker) {
				this.copyColumn(source, target, marker)
			} else if (marker instanceof HasOneRelationMarker) {
				this.copyHasOneRelation(source, target, marker)
			} else if (marker instanceof HasManyRelationMarker) {
				this.copyHasManyRelation(source, target, marker)
			}
		}
	}

	public copyHasOneRelation(source: EntityAccessor, target: EntityAccessor, marker: HasOneRelationMarker) {
		for (const handler of this.handlers) {
			if (handler.copyHasOneRelation?.({ copier: this, source, target, marker })) {
				return
			}
		}
		const subEntity = source.getEntity(marker.parameters)
		if (marker.parameters.expectedMutation === 'connectOrDisconnect') {
			if (!subEntity.existsOnServer && subEntity.hasUnpersistedChanges) {
				throw 'cannot copy'
			}
			target.connectEntityAtField(marker.parameters.field, subEntity)
		} else if (marker.parameters.expectedMutation === 'anyMutation' || marker.parameters.expectedMutation === 'createOrDelete') {
			const subTarget = target.getEntity(marker.parameters)
			this.copy(subEntity, subTarget)
		}
	}

	public copyHasManyRelation(source: EntityAccessor, target: EntityAccessor, marker: HasManyRelationMarker) {
		for (const handler of this.handlers) {
			if (handler.copyHasManyRelation?.({ copier: this, source, target, marker })) {
				return
			}
		}
		const list = source.getEntityList(marker.parameters)
		const targetList = target.getEntityList(marker.parameters)
		if (marker.parameters.expectedMutation === 'connectOrDisconnect') {
			for (const subEntity of list) {
				if (!subEntity.existsOnServer && subEntity.hasUnpersistedChanges) {
					throw 'cannot copy'
				}
				targetList.connectEntity(subEntity)
			}
		} else if (marker.parameters.expectedMutation === 'anyMutation' || marker.parameters.expectedMutation === 'createOrDelete') {
			targetList.disconnectAll()
			for (const subEntity of list) {
				targetList.createNewEntity((target) => {
					this.copy(subEntity, target())
				})
			}
		}
	}

	public copyColumn(source: EntityAccessor, target: EntityAccessor, marker: FieldMarker) {
		if (marker.fieldName === PRIMARY_KEY_NAME) {
			return
		}
		for (const handler of this.handlers) {
			if (handler.copyColumn?.({ copier: this, source, target, marker })) {
				return
			}
		}
		const sourceValue = source.getField(marker.fieldName).value
		target.getField(marker.fieldName).updateValue(sourceValue)
	}
}
