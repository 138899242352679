import { Component, Field, FieldContainer, useEntity, usePersist } from '@contember/admin'
import * as React from 'react'
import Select from 'react-select'

import { useCleverMapsViews } from '../hooks/cleverMaps/useCleverMapsViews'
import { ViewContent } from '../clevermaps-js-sdk'

type SelectOption = {
	value: {
		viewName: string
		shareId?: string | null
	}
	filterTitle: string
	label: React.ReactNode
}

const optionView = (option: ViewContent) => ({
	value: {
		viewName: option.name,
	},
	filterTitle: option.title,
	label: (
		<div className="flex justify-between items-center">
			<span>{option.title}</span>
		</div>
	),
})

export const CleverMapsViewSelect = Component<{ field: string; label: React.ReactNode; cleverMapsProjectId: string }>(
	({ field, label, cleverMapsProjectId }) => {
		const entity = useEntity(field)
		const persist = usePersist()
		const viewName = entity.getField<string>('viewName')
		const shareId = entity.getField<string | null>('shareId')
		const views = useCleverMapsViews({ projectId: cleverMapsProjectId })

		const options: SelectOption[] = React.useMemo(() => views?.content?.map((view) => optionView(view)), [views]) ?? [];

		const findSelectOption = React.useMemo(
			() =>
				options.find((option) => {
					return option.value.viewName === viewName.value && option.value.shareId == shareId.value
				}),
			[options, viewName, shareId],
		)

		const [selectedOption, setSelectedOption] = React.useState<SelectOption | undefined>(findSelectOption)

		const onChange = React.useCallback(
			(newValue: SelectOption) => {
				viewName.updateValue(newValue.value.viewName)
				shareId.updateValue(newValue.value.shareId)

				if (!newValue) return
				setSelectedOption(newValue)
				persist()
			},
			[viewName, shareId],
		)

		return (
			<FieldContainer label={label}>
				<Select<SelectOption>
					value={selectedOption ?? findSelectOption}
					onChange={(newValue) => onChange(newValue)}
					options={options}
					isSearchable
					filterOption={React.useCallback((candidate, input) => {
						if (candidate.data.filterTitle.toLowerCase().includes(input.toLowerCase())) {
							return true
						} else {
							return false
						}
					}, [])}
				/>
			</FieldContainer>
		)
	},
	({ field }) => (
		<>
			<Field field={field + '.viewName'} />
			<Field field={field + '.shareId'} />
		</>
	),
	'CleverMapsViewSelect',
)
