import * as React from 'react'
import { EntityListAccessor } from '@contember/admin'
import { CleverMapsSDKContext } from '../../cleverMapsSDK'

type CleverMapLinkProps = {
	reference: { targetCleverMapLinkItems: EntityListAccessor }
	children: React.ReactElement
}

export const CleverMapLink = ({ reference, children }: CleverMapLinkProps) => {
	const { iframes } = React.useContext(CleverMapsSDKContext)
	const linkItems = Array.from(reference.targetCleverMapLinkItems).map((linkItem) => {
		return { map: linkItem.getEntity('targetCleverMap').getField('id').value, state: linkItem.getField('state').value }
	})

	return (
		// eslint-disable-next-line jsx-a11y/anchor-is-valid
		<a
			href="#"
			onClick={(e) => {
				e.preventDefault()
				linkItems.forEach((linkItem) => {
					const { iframe } = iframes.find((iframe) => iframe.cleverMapId === linkItem.map)
					if (iframe) {
						iframe.setState(linkItem.state)
					}
				})
			}}
		>
			{children}
		</a>
	)
}
