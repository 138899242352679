import { EntityAccessor } from '@contember/admin';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import React, { useCallback } from 'react';
import { resetIframeFilters } from '../services/iframeFilter';
import filterValueTransform from '../utils/filterValueTransform';
import { CleverMapsSDKContext } from './cleverMapsSDK';
import { useHandleIFrameSubscription } from '../hooks/cleverMaps/useHandleIFrameSubscription';
import iframeValueTransform from '../utils/iframeValueTransform';

type SingleSelectFieldProps = {
    field: EntityAccessor;
    reference: { form: EntityAccessor };
}

export const SingleSelectField: React.FC<SingleSelectFieldProps> = ({ field, reference }) => {
    const { iframes, activeFiltersCount, increaseFiltersCount, decreaseFiltersCount } = React.useContext(CleverMapsSDKContext)
    const cleverMapIds = Array.from(reference.form.getEntityList('cleverMaps')).map((cleverMap) => cleverMap.getField('id').value)
    const fieldId = field.id as string
    const valuesArray = Array.from(field.getEntityList({ field: 'values' }))
    const filterName = field.getField<string>('name').value
    const [filterId, setFilterId] = React.useState<null | string>(null)
    const [filterValue, setFilterValue] = React.useState<null | string>(null)

    React.useEffect(() => {
        if (activeFiltersCount === 0) {
            resetIframeFilters(iframes, cleverMapIds, filterId, setFilterId, setFilterValue)
        }
    }, [activeFiltersCount, cleverMapIds, filterId, iframes])

    const addFilters = useCallback((...params) => {
        const value = params[1]?.value
        if (params[2].property === filterName) {
            increaseFiltersCount()
            if (!value) {
                setFilterId(params[0])
            } else {
                setFilterValue(iframeValueTransform(value))
                setFilterId(params[0])
            }
        }
    }, [filterName, increaseFiltersCount])

    useHandleIFrameSubscription(cleverMapIds, addFilters)

    const onValueChange = (newValue: {
        value: string;
        label: string;
    }) => {
        if (!newValue) {
            resetIframeFilters(iframes, cleverMapIds, filterId, setFilterId, setFilterValue)
            decreaseFiltersCount()
        } else {
            const { value } = newValue
            iframes
                .filter((iframe) => cleverMapIds.includes(iframe.cleverMapId))
                .forEach((it) => {
                    if (!filterValue && !filterId) {
                        it.iframe.message.addFilter(filterName, { value: filterValueTransform(value) }, filterName)
                    } else {
                        it.iframe.message.setFilter(filterId, { value: filterValueTransform(value) })
                        setFilterValue(value)
                    }
                })
        }
    }

    return (<FormControl key={fieldId} variant="outlined" size="small">
        <Autocomplete<{ value: string; label: string }>
            value={
                filterValue
                    ? {
                        // type mismatch is happening here on controlled autocomplete
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        value: valuesArray
                            .find((item) => {
                                return item.getField<string>('value').value === filterValue
                            })
                            ?.getField<string>('value').value ?? '',
                        label: valuesArray
                            .find((item) => {
                                return item.getField<string>('value').value === filterValue
                            })
                            ?.getField<string>('label').value ?? '',
                    }
                    : { value: '', label: '' }
            }
            options={valuesArray.map((item) => ({
                value: item.getField<string>('value').value!,
                label: item.getField<string>('label').value!,
            }))}
            getOptionLabel={(option) => (option && typeof option != 'string' ? String(option.label) : '')}
            disablePortal={true}
            onChange={(event, newValue) => {
                onValueChange(newValue)
            }}
            renderInput={(params) => <TextField {...params} label={<>{field.getField('label').value}</>} />}
        />
    </FormControl>)
}
