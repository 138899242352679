// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import { ComponentType, memo, ReactNode } from 'react'
import { DataBindingProvider, EntitySubTree, EntitySubTreeAdditionalProps, PageProvider, SugaredQualifiedSingleEntity } from '@contember/admin'
import { useOnPersistSuccess } from '../hooks/useOnPersistSuccess'
import { RedirectOnSuccessTarget } from '../hooks/useEntityRedirectOnPersistSuccess'
import { DataBindingStateRenderer } from './DataBindingStateRenderer'

export type EditScopeProps = SugaredQualifiedSingleEntity &
	EntitySubTreeAdditionalProps & {
		children: ReactNode
		redirectOnSuccess?: RedirectOnSuccessTarget
		refreshDataBindingOnPersist?: boolean
	}

export const EditScope: Partial<PageProvider<EditScopeProps>> & ComponentType<EditScopeProps> = memo(
	({ children, redirectOnSuccess, onPersistSuccess, refreshDataBindingOnPersist, ...entityProps }: EditScopeProps) => (
		<DataBindingProvider stateComponent={DataBindingStateRenderer} refreshOnPersist={refreshDataBindingOnPersist ?? true}>
			<EntitySubTree {...entityProps} onPersistSuccess={useOnPersistSuccess({ redirectOnSuccess, onPersistSuccess })}>
				{children}
			</EntitySubTree>
		</DataBindingProvider>
	),
)

EditScope.displayName = 'EditScope'
