import * as React from 'react'
import { useApiBaseUrl, useSessionToken } from '@contember/admin'

export const cleverMapsGetEndpoints = async <T,>(url: string, token: string, paths: string[]): Promise<{ response: T[] | null, error: number | null }> => {
	const responses = await Promise.all(paths.map(
		(path) => (
			fetch(`${url}${path}`, {
				headers: {
					'Authorization': `Bearer ${token}`,
					'Accept': 'application/json',
				},
			})
		))
	)

	const payloads = []

	for (const response of responses) {
		if (!response.ok) {
			return ({
				response: null,
				error: response.status
			})
		}

		payloads.push(await response.json())
	}

	return ({
		response: payloads,
		error: null
	})

}


export const useCleverMapsGetEndpoints = <T,>(paths: string[] | null): { response: T[] | null, error: number | null } => {
	const url = useApiBaseUrl()
	const token = useSessionToken()
	const [payload, setPayload] = React.useState<T[] | null>(null)
	const [error, setError] = React.useState<number | null>(null)

	const isMountedRef = React.useRef(true)

	React.useEffect(() => () => {
		isMountedRef.current = false
	}, [])

	React.useEffect(
		() => {
			if (paths && url && token) {
				(async () => {
					const {response, error} = await cleverMapsGetEndpoints<T>(url, token, paths)

					if (!isMountedRef.current) {
						return
					}

					setPayload(response)
					setError(error)
					
				})()
			}
		},
		[url, token, paths],
	)

	return { response: payload, error }
}
