import { FieldValue } from '@contember/admin';
import React, { useCallback } from 'react';
import { CleverMapsSDKContext } from '../../components/cleverMapsSDK';

export const useHandleIFrameSubscription = (
    cleverMapIds: Array<FieldValue>,
    addFilters: (params: unknown) => void
) => {
    const { iframes } = React.useContext(CleverMapsSDKContext);

    const subscribeListeners = useCallback(() => {
        iframes
            .filter((iframe) => cleverMapIds.includes(iframe.cleverMapId))
            .forEach((iframe) => {
                iframe.iframe.message.addAddFilterListener(addFilters);
            });
    }, [addFilters, cleverMapIds, iframes]);

    const unsubscribeListeners = useCallback(() => {
        iframes
            .filter((iframe) => cleverMapIds.includes(iframe.cleverMapId))
            .forEach((iframe) => {
                iframe.iframe.message.removeAddFilterListener(addFilters);
            });
    }, [addFilters, cleverMapIds, iframes]);

    React.useEffect(() => {
        subscribeListeners();
        return () => {
            unsubscribeListeners();
        };
    }, [iframes, subscribeListeners, unsubscribeListeners]);
};
