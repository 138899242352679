import * as React from 'react'

import {Component, Field, Stack, useEntity} from '@contember/admin'
import {CleverMapsViewSelect} from './CleverMapsViewSelect'
import {ProjectIdContext} from './ProjectIdContext'

import generate from 'project-name-generator'

type CleverMapsFieldProps = {
	field: string
	label: string
	showMap?: boolean
}

export const CleverMapsField = Component<CleverMapsFieldProps>(
	({ field, label, showMap = true }) => {
		const projectId = React.useContext(ProjectIdContext)
		const entity = useEntity(field)
		const nameField = entity.getField<string>('name')

		React.useEffect(() => {
			if (!nameField.value) {
				nameField.updateValue(generate().dashed)
			}

			return
		}, [nameField])

		return (
			<Stack direction="vertical">
				{showMap && (
					<Stack direction="vertical">
						<div>
							Map unique name <code>{nameField.value}</code>
						</div>
					</Stack>
				)}
				<CleverMapsViewSelect field={field} label={label} cleverMapsProjectId={projectId} />
			</Stack>
		)
	},
	({ field }) => (
		<>
			<Field field={field + '.name'} />
			<CleverMapsViewSelect field={field} label={undefined} cleverMapsProjectId={undefined} />
		</>
	),
	'CleverMapsField',
)
