import { EntityAccessor } from '@contember/admin';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React, { useCallback } from 'react';
import { resetIframeFilters } from '../services/iframeFilter';
import filterValueTransform from '../utils/filterValueTransform';
import { CleverMapsSDKContext } from './cleverMapsSDK';
import iframeValueTransform from '../utils/iframeValueTransform';
import { useHandleIFrameSubscription } from '../hooks/cleverMaps/useHandleIFrameSubscription';

type RadioFieldProps = {
    field: EntityAccessor;
    reference: { form: EntityAccessor };
}

export const RadioField: React.FC<RadioFieldProps> = ({ field, reference }) => {
    const { iframes, activeFiltersCount, increaseFiltersCount } = React.useContext(CleverMapsSDKContext)
    const cleverMapIds = Array.from(reference.form.getEntityList('cleverMaps')).map((cleverMap) => cleverMap.getField('id').value)
    const fieldId = field.id as string
    const valuesArray = Array.from(field.getEntityList({ field: 'values' }))
    const label = field.getField<string>('label').value
    const filterName = field.getField<string>('name').value
    const [filterId, setFilterId] = React.useState<null | string>(null)
    const [filterValue, setFilterValue] = React.useState<null | string>(null)

    React.useEffect(() => {
        if (activeFiltersCount === 0) {
            resetIframeFilters(iframes, cleverMapIds, filterId, setFilterId, setFilterValue)
        }
    }, [activeFiltersCount, cleverMapIds, filterId, iframes])

    const addFilters = useCallback((...params) => {
        const value = params[1]?.value
        if (params[2].property === filterName) {
            increaseFiltersCount()
            if (!value) {
                setFilterId(params[0])
            } else {
                setFilterValue(iframeValueTransform(value))
                setFilterId(params[0])
            }
        }
    }, [filterName, increaseFiltersCount])

    useHandleIFrameSubscription(cleverMapIds, addFilters)

    const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        iframes
            .filter((iframe) => cleverMapIds.includes(iframe.cleverMapId))
            .forEach((it) => {
                if (!filterValue && !filterId) {
                    it.iframe.message.addFilter(filterName, { value: filterValueTransform(value) }, filterName)
                } else {
                    it.iframe.message.setFilter(filterId, { value: filterValueTransform(value) })
                    setFilterValue(value)
                }
            })
    }

    return (
        <FormControl key={fieldId} variant="outlined" size="small">
            <FormLabel id={fieldId}>{label}</FormLabel>
            <RadioGroup row aria-labelledby={fieldId} name={fieldId}>
                {valuesArray.map((value, i) => (
                    <FormControlLabel
                        key={i}
                        value={value.getField<string>('value').value!}
                        control={
                            <Radio
                                color="primary"
                                onChange={(event) => {
                                    onValueChange(event)
                                }}
                                checked={filterValue === value.getField<string>('value').value!}
                            />
                        }
                        label={value.getField<string>('label').value!}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    )
}
