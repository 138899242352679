import React from 'react'

import { FilterGroup, FilterGroupTypes } from '../../clevermaps-js-sdk'
import { useCleverMapsGetEndpoint } from '../useCleverMapsGetEndpoint'
import { useCleverMapsPostEndpoint } from '../useCleverMapsPostEndpoint'

type useCleverMapsPropertyValuesProps = {
	url: string
	datasetName: string | null
	filterGroupType: FilterGroupTypes
	orderBy?: FilterGroup['orderBy']
}

export const useCleverMapsPropertyValues = (props: useCleverMapsPropertyValuesProps) => {
	const valueType = {
		histogram: 'propertyValuesDistributions',
		globalDate: 'dateRanges',
		date: 'dateRanges',
		multiSelect: 'propertyValues',
		singleSelect: 'propertyValues',
	}

	let url = `${props.url}/${valueType[props.filterGroupType]}?size=20000`
	let body
	
	if (['multiSelect', 'singleSelect'].includes(props.filterGroupType)) url = url + `&property=${props.datasetName}`
	if (props?.orderBy && props.orderBy.length) {
		url = url + `&sort=${props.orderBy[0].property},${props.orderBy[0].direction}`
	}
	if (props.filterGroupType === 'histogram') url = url + `&property=${props.datasetName}&buckets=2`
	if (['globalDate', 'date'].includes(props.filterGroupType)) body = { from: props.datasetName }

	const [path, setPath] = React.useState<string | null>(null)
	const { response: res, error: err } = useCleverMapsPostEndpoint(props.datasetName ? url : null, body)
	const { response, error } = useCleverMapsGetEndpoint(path, true)

	if (err) throw new Error(String(err))

	React.useEffect(() => {
		if (res) {
			setPath(res.get('location'))
		}
	}, [res])

	if (error) throw new Error(String(error))

	return response as { content?: unknown }
}
