import { AccessorTreeState, Button, Message, MiscPageLayout, SpinnerOverlay } from '@contember/admin'
import * as React from 'react'

export interface DataBindingStateRendererProps {
	accessorTreeState: AccessorTreeState
	children: React.ReactNode
}

export function DataBindingStateRenderer({ accessorTreeState, children }: DataBindingStateRendererProps) {
	const reload = React.useCallback(() => {
		window.location.reload()
	}, [])

	if (accessorTreeState.name === 'initializing') {
		return <SpinnerOverlay />
	}

	if (accessorTreeState.name === 'error') {
		console.log(accessorTreeState.error)
		switch (accessorTreeState.error.type) {
			case 'unauthorized':
				return (
					<MiscPageLayout>
						<h3>Your session expired.</h3>
						<Button onClick={reload}>Log in</Button>
					</MiscPageLayout>
				)

			case 'networkError':
				if (import.meta.env.DEV) {
					throw new Error(accessorTreeState.error.metadata.responseText)
				}

				return <Message intent="danger">Network error</Message>

			case 'gqlError':
				if (import.meta.env.DEV) {
					throw new Error(JSON.stringify(accessorTreeState.error.errors, null, '  '))
				}

				return <Message intent="danger">Unknown error</Message>

			case 'unknownError':
			default:
				return <Message intent="danger">Unknown error</Message>
		}
	}

	return <>{children}</>
}
