import { FilterGroupTypes } from '../../clevermaps-js-sdk';

export const useCleverMapsFilterOptions = (filterType: FilterGroupTypes) => {
    const options = [
		{ value: 'multiSelectField', label: 'Multi select' },
		{ value: 'singleSelectField', label: 'Single select' },
		{ value: 'checkboxesField', label: 'Checkboxes' },
		{ value: 'radioField', label: 'Radio' },
		{ value: 'rangeField', label: 'Range' },
	].filter((option) => {
		return (filterType === 'multiSelect' && (option.value === 'multiSelectField' || option.value === 'checkboxesField')) || 
        (filterType === 'singleSelect' && (option.value === 'singleSelectField' || option.value === 'radioField')) || 
        (filterType === 'histogram' && option.value === 'rangeField')
	})
    return options;
}
