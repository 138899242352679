import { MegaphoneIcon } from '@heroicons/react/20/solid'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'

export const Announcement = () => (
	<div className="mt-10">
		<div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
			<div className="p-2 rounded-lg bg-clever-bg shadow-lg sm:p-3">
				<div className="flex items-center justify-between flex-wrap">
					<div className="w-0 flex-1 flex items-center">
						<span className="flex p-2 rounded-lg bg-white">
							<MegaphoneIcon className="h-6 w-6 text-clever-bg" aria-hidden="true" />
						</span>
						<p className="ml-3 font-medium text-white truncate">
							<span className="inline">Big news! We&apos;re excited to announce a our new Stories builder.</span>
						</p>
					</div>
					<div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<a
							href="#"
							className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-clever-bg bg-white hover:bg-indigo-50"
						>
							Learn more
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
)
