// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import { DataBindingProvider, DataGrid, DeleteEntityButton, GenericCell, TextCell } from '@contember/admin'
import { Header } from '../components/Header'
import { Layout } from '../components/Layout'
import { RecoverEntityButton } from '../components/RecoverEntityButton'
import { DataBindingStateRenderer } from '../components/DataBindingStateRenderer'

const StoryDeleteList = () => (
	<Layout>
		<Header />
		<DataBindingProvider stateComponent={DataBindingStateRenderer} refreshOnPersist>
			<div className="max-w-7xl w-full mx-auto mt-10 px-2 sm:px-6 lg:px-8">
				<DataGrid entities="Story[deletedAt != null]" itemsPerPage={25}>
					<TextCell field="title" header="Title" />
					<GenericCell shrunk canBeHidden={false}>
						<RecoverEntityButton field="deletedAt">Recover</RecoverEntityButton>
					</GenericCell>
					<GenericCell shrunk canBeHidden={false}>
						<DeleteEntityButton immediatePersist intent="danger" className="shadow-none rounded-full" />
					</GenericCell>
				</DataGrid>
			</div>
		</DataBindingProvider>
	</Layout>
)

export default StoryDeleteList;
