import * as React from 'react'
import {
	SugaredUnconstrainedQualifiedSingleEntity,
	EntitySubTreeAdditionalProps,
	EntitySubTreeAdditionalCreationProps,
	PageProvider,
	DataBindingProvider,
	EntitySubTree,
} from '@contember/admin'
import { useOnPersistSuccess } from '../hooks/useOnPersistSuccess'
import { DataBindingStateRenderer } from './DataBindingStateRenderer'
import { RedirectOnSuccessTarget } from '../hooks/useEntityRedirectOnPersistSuccess'

export type CreateScopeProps = Omit<SugaredUnconstrainedQualifiedSingleEntity, 'isCreating'> &
	EntitySubTreeAdditionalProps &
	EntitySubTreeAdditionalCreationProps & {
		children: React.ReactNode
		redirectOnSuccess?: RedirectOnSuccessTarget
	}

export const CreateScope: Partial<PageProvider<CreateScopeProps>> & React.ComponentType<CreateScopeProps> = React.memo(
	({ children, redirectOnSuccess, onPersistSuccess, ...entityProps }: CreateScopeProps) => {
		return (
			<DataBindingProvider stateComponent={DataBindingStateRenderer}>
				<EntitySubTree {...entityProps} onPersistSuccess={useOnPersistSuccess({ redirectOnSuccess, onPersistSuccess })} isCreating>
					{children}
				</EntitySubTree>
			</DataBindingProvider>
		)
	},
)

CreateScope.displayName = 'CreateScope'
