// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import {Component, Field, HasMany, HasOne} from '@contember/admin'
import {FormFields} from './FormFields'

export const ContentFields = Component(
	() => (
		<HasOne field="content">
			<HasMany field="parts">
				<Field field="order" />
				<Field field="json" />
				<HasMany field="references">
					<Field field="type" />
					<HasOne field="target">
						<Field field="type" />
						<Field field="url" />
						<HasOne field="story" />
						<HasOne field="chapter" />
					</HasOne>
					<HasMany field="targetCleverMaps">
						<Field field="viewName" />
						<Field field="shareId" />
						<Field field="showMeasure" />
						<Field field="showCompare" />
						<Field field="showSearch" />
						<Field field="infoBoxDefaultExpanded" />
						<Field field="showViewSwitch" />
						<Field field="showProjectControls" />
						<Field field="showQuickShare" />
						<Field field="showQuickExport" />
						<Field field="fullScreenButton" />
						<Field field="interactivityAfterClick" />
						<Field field="themeLogoUrl" />
						<Field field="themePrimaryColor" />
						<Field field="name" />
						<HasMany field="forms" />
					</HasMany>
					<HasMany field="targetCleverMapLinkItems">
						<Field field="order" />
						<Field field="state" />
						<HasOne field="targetCleverMap" />
					</HasMany>
					<HasOne field="image">
						<Field field="url" />
						<Field field="width" />
						<Field field="height" />
						<Field field="alt" />
					</HasOne>
					<HasOne field="embed">
						<Field field="type" />
						<Field field="embedId" />
					</HasOne>
					<HasOne field="stack">
						<HasOne field="targetCleverMap">
							<Field field="viewName" />
							<Field field="shareId" />
							<Field field="showMeasure" />
							<Field field="showCompare" />
							<Field field="showSearch" />
							<Field field="infoBoxDefaultExpanded" />
							<Field field="showViewSwitch" />
							<Field field="showProjectControls" />
							<Field field="showQuickShare" />
							<Field field="showQuickExport" />
							<Field field="fullScreenButton" />
							<Field field="interactivityAfterClick" />
							<Field field="themeLogoUrl" />
							<Field field="themePrimaryColor" />
							<Field field="name" />
							<HasMany field="forms" />
						</HasOne>
						<Field field="layout" />
						<HasOne field="content">
							<HasMany field="parts">
								<Field field="order" />
								<Field field="json" />
								<HasMany field="references">
									<Field field="type" />
									<HasOne field="target">
										<Field field="type" />
										<Field field="url" />
										<HasOne field="story" />
										<HasOne field="chapter" />
									</HasOne>
									<HasMany field="targetCleverMaps">
										<Field field="viewName" />
										<Field field="shareId" />
										<Field field="showMeasure" />
										<Field field="showCompare" />
										<Field field="showSearch" />
										<Field field="infoBoxDefaultExpanded" />
										<Field field="showViewSwitch" />
										<Field field="showProjectControls" />
										<Field field="showQuickShare" />
										<Field field="showQuickExport" />
										<Field field="fullScreenButton" />
										<Field field="interactivityAfterClick" />
										<Field field="themeLogoUrl" />
										<Field field="themePrimaryColor" />
										<Field field="name" />
										<HasMany field="forms" />
									</HasMany>
									<HasMany field="targetCleverMapLinkItems">
										<Field field="order" />
										<Field field="state" />
										<HasOne field="targetCleverMap" />
									</HasMany>
									<HasOne field="image">
										<Field field="url" />
										<Field field="width" />
										<Field field="height" />
										<Field field="alt" />
									</HasOne>
									<HasOne field="embed">
										<Field field="type" />
										<Field field="embedId" />
									</HasOne>
									<FormFields />
								</HasMany>
							</HasMany>
						</HasOne>
					</HasOne>
					<FormFields />
				</HasMany>
			</HasMany>
		</HasOne>
	),
	'ContentFields',
)
