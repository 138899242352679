const filterValueTransform = (input: string | null): string | number | null => {
	if (input !== null) {
		if (input === 'NULL') {
			return null
		}
		if (input.includes('number_')) {
			return Number(input.replace('number_', ''))
		}
	}
	return input
}

export default filterValueTransform
