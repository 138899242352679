// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import { Link, RichTextRenderer as ContemberRichTextRenderer } from '@contember/admin'
import { CleverMap, linkColor } from './referenceRenderer/CleverMap'
import { richTextBlockParser } from './richTextBlockParser'
import { CleverMapForm } from './referenceRenderer/CleverMapForm'
import { CleverMapBulkFilter } from './referenceRenderer/CleverMapBulkFilter'
import { CleverMapLink } from './referenceRenderer/CleverMapLink'
import styled from '@emotion/styled'

const StoryLink = styled(Link)({
	color: linkColor
})

const ExternalLink = styled('a')({
	color: linkColor
})

type RichTextRendererProps = {
	projectId: string
	parts: unknown[]
}

export type FieldTypeProps = 'multiSelectField' | 'checkboxField' | 'checkboxesField' | 'singleSelectField' | 'radioField' | 'rangeField'

export const RichTextRenderer = ({ projectId, parts }: RichTextRendererProps) => (
	<ContemberRichTextRenderer
		referenceRenderers={{
			cleverMap: ({ reference }) => <CleverMap reference={reference} projectId={projectId} />,
			cleverMapForm: ({ reference }) => <CleverMapForm reference={reference}/>,
			image: ({ reference }) => (
				<div className="text-center">
					<div className="inline-block">
						<img
							alt=''
							src={reference.image.getField('url').value}
							width={reference.image.getField('width').value}
							height={reference.image.getField('height').value}
							className="my-6 mx-auto max-w-full !h-auto"
						/>
						{reference.image.getField('alt').value && (
							<p className="text-left -mt-6">
								<em>{reference.image.getField('alt').value}</em>
							</p>
						)}
					</div>
				</div>
			),
			embed: ({ reference }) => {
				const youTubeId = reference.embed.getField('embedId').value

				if (youTubeId === null) {
					return null
				}
				return (
					<div data-contember-type="embed">
						<div className="yt_responsive_wrapper">
							<iframe
								title='embedded-youtube'
								width="560"
								height="315"
								src={`https://www.youtube-nocookie.com/embed/${youTubeId}`}
								referrerPolicy="no-referrer"
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								loading="lazy"
								frameBorder="0"
								allowFullScreen
							/>
						</div>
					</div>
				)
			},
		}}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		renderElement={({ fallback, element, children, reference }: any) => {
			if (element.type === 'link') {
				if (reference?.target.getField('type').value === 'chapter') {
					return <StoryLink to={`viewChapter(chapter: '${reference.target.getEntity('chapter').id}')`}>{children}</StoryLink>
				}
				if (reference?.target.getField('type').value === 'story') {
					return <StoryLink to={`viewStory(story: '${reference.target.getEntity('story').id}')`}>{children}</StoryLink>
				}

				return <ExternalLink href={reference.target.getField('url').value} target="_blank" rel="noreferrer">{children}</ExternalLink>
			} else if (element.type === 'bulkFilterCleverMap') {
				return <CleverMapBulkFilter reference={reference}>{children}</CleverMapBulkFilter>
			} else if (element.type === 'linkCleverMap') {
				return <CleverMapLink reference={reference}>{children}</CleverMapLink>
			}
			return fallback
		}}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		blocks={parts.map(richTextBlockParser as any)}
		sourceField="json"
	/>
)
