// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import { Component, DataBindingProvider, EntitySubTree, Field, HasOne, Layout, Link, useEntitySubTree, useEnvironment } from '@contember/admin'
import { ContentFields } from '../components/view/staticRenderers/ContentFields'
import { Header } from '../components/view/Header'
import { RichTextRenderer } from '../components/view/RichTextRenderer'
import { StoryFields } from '../components/view/staticRenderers/StoryFields'
import { DataBindingStateRenderer } from '../components/DataBindingStateRenderer'

const ViewChapter = Component(
	() => {
		const env = useEnvironment()
		const chapter = useEntitySubTree('chapter')
		const story = chapter.getEntity('story')
		const chapters = story.getEntityList({ field: 'chapters', orderBy: 'order' })
		const arrayChapters = Array.from(chapters)
		const chapterOrder = chapter.getField<number>('order').value
		const style = story.getField<string>('style').value
		const isStyle = story.getField<boolean>('useStyle').value
		const isMobileHeader = story.getField<boolean>('useMobileHeader').value

		return (
			<>
				{isStyle && <style>{style}</style>}
				<div className="custom-layout">
					<Header
						title={story.getField<string>('title').value!}
						chapters={chapters}
						currentChapterId={env.getParameter('chapter')}
						storyId={story.id}
						isMobileHeader={isMobileHeader}
					/>
					<div className="bg-gray-100 pb-10">
						<div className="py-16 px-4 prose max-w-none sm:px-6 lg:px-8">
							<RichTextRenderer
								projectId={story.getField<string>('cleverMapsId').value!}
								parts={Array.from(chapter.getEntityList('content.parts'))}
							/>
						</div>
						<div className="text-center">
							<p className="mb-5 text-gray-500">Continue reading</p>
							<div className="my-2 flex gap-3 justify-center">
								{Number(chapterOrder) === chapterOrder && arrayChapters[chapterOrder - 1] && (
									<Link to={`viewChapter(chapter: '${arrayChapters[chapterOrder - 1].id}')`}>
										<span className="border border-red-500 text-red-500 py-3 px-6 rounded">
											<>{arrayChapters[chapterOrder - 1].getField('title').value}</>
										</span>
									</Link>
								)}
								{Number(chapterOrder) === chapterOrder && !arrayChapters[chapterOrder - 1] && (
									<Link to={`viewStory(story: '${story.id}')`}>
										<span className="border border-red-500 text-red-500 py-3 px-6 rounded">Home</span>
									</Link>
								)}
								{Number(chapterOrder) === chapterOrder && arrayChapters[chapterOrder + 1] && (
									<Link to={`viewChapter(chapter: '${arrayChapters[chapterOrder + 1].id}')`}>
										<span className="bg-red-500 text-white py-3 px-6 rounded hover:bg-red-600">
											<>{arrayChapters[chapterOrder + 1].getField('title').value}</>
										</span>
									</Link>
								)}
							</div>
						</div>
					</div>
				</div>
			</>
		)
	},
	() => (
		<EntitySubTree entity="Chapter(id = $chapter)" alias="chapter">
			<Field field="order" />
			<ContentFields />
			<HasOne field="story">
				<StoryFields />
			</HasOne>
		</EntitySubTree>
	),
	'ViewChapter',
)

const ViewChapterPage = () => (
	<Layout scheme="light">
		<DataBindingProvider stateComponent={DataBindingStateRenderer}>
			<div className="bg-white">
				<main>
					<div>
						<ViewChapter />
					</div>
				</main>
			</div>
		</DataBindingProvider>
	</Layout>
)

export default ViewChapterPage;
