import { useCleverMapsGetEndpoint } from '../useCleverMapsGetEndpoint'

export interface UseCleverMapsGetProjectSettings$Input {
	projectId: string | null | undefined
	settingName: string | null | undefined
}

export const useCleverMapsGetProjectSettings = ({ projectId, settingName }: UseCleverMapsGetProjectSettings$Input) => {
	const url = projectId && settingName ? `/rest/projects/${projectId}/md/projectSettings?name=${settingName}` : null
	const { response, error } = useCleverMapsGetEndpoint<unknown>(url)

	if (error) {
		throw new Error(String(error))
	}

	return response
}
