import * as React from 'react'
import { Component, Field, useField, useRedirect } from '@contember/admin'
import { useCleverMapsCanEdit } from '../hooks/cleverMaps/useCleverMapsCanEdit'

export type UserCanEditRouteCheckProps = {
	children: React.ReactNode
	redirectRoute: string
}

export default Component<UserCanEditRouteCheckProps>(
	({ children, redirectRoute }) => {
		const redirect = useRedirect()
		const cleverMapsId = useField<string>('cleverMapsId').value
		const cantEditRole = useCleverMapsCanEdit({ projectId: cleverMapsId })
		if (cantEditRole === false) {
			redirect(redirectRoute)
		}
		return <>{children}</>
	},
	({ children }) => (
		<>
			<Field field='cleverMapsId' />
			{children}
		</>
	),
	'UserCanEditRouteCheck',
)