import * as React from 'react'
import * as Sentry from '@sentry/react';
import cmSdk, { CleverMapsIframe, CleverMapsSDKProps, CleverMapsSDK } from 'clevermaps-js-sdk'

export type CleverMapsSDKContextProps = {
	sdk: CleverMapsSDK | null,
	iframes: CleverMapsSDKIframe[],
	activeFiltersCount: number,
	setIframes: React.Dispatch<React.SetStateAction<CleverMapsSDKIframe[]>>
	increaseFiltersCount: () => void
	decreaseFiltersCount: () => void
	resetFiltersCount: () => void
}

export type CleverMapsSDKIframe = {
	iframe: CleverMapsIframe
	projectId: string
	viewName: string
	cleverMapId: string
}

export const CleverMapsSDKContext = React.createContext<CleverMapsSDKContextProps>({
	sdk: null, iframes: [],
	setIframes: () => { },
	activeFiltersCount: 0,
	increaseFiltersCount: () => { },
	decreaseFiltersCount: () => { },
	resetFiltersCount: () => { }
})

const CleverMapsSDK = ({ children }: CleverMapsSDKProps) => {
	const [sdk, setSdk] = React.useState(null)
	const [iframes, setIframes] = React.useState([])
	const [activeFiltersCount, setActiveFiltersCount] = React.useState(0)

	const increaseFiltersCount = React.useCallback(() => {
		setActiveFiltersCount((count) => count + 1)
	}, [])

	const decreaseFiltersCount = React.useCallback(() => {
		setActiveFiltersCount((count) => (count - 1 > 0) ? count - 1 : 0)
	}, [])

	const resetFiltersCount = React.useCallback(() => {
		setActiveFiltersCount(0)
	}, [])

	React.useEffect(() => {
		; (async () => {
			const sdk = cmSdk(import.meta.env.VITE_CLEVER_MAPS_SDK_BASE_URL)
			await sdk.authenticate()
			setSdk(sdk)
		})()
	}, [])

	return (
		<Sentry.ErrorBoundary fallback={<p>An error occurred. Please try to refresh the page, or contact our support.</p>}>
			<CleverMapsSDKContext.Provider value={{ sdk, iframes, setIframes, activeFiltersCount, increaseFiltersCount, decreaseFiltersCount, resetFiltersCount }}>
				{children}
			</CleverMapsSDKContext.Provider>
		</Sentry.ErrorBoundary>
	)
}

export default CleverMapsSDK
