// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import { Block, Component, DiscriminatedBlocks, Field, HasOne, SelectField, TextField } from '@contember/admin'

export interface LinkFieldProps {
	field: string
	label?: string
}

export const LinkField = Component<LinkFieldProps>(({ field, label }) => {
	return (
		<HasOne field={field}>
			<DiscriminatedBlocks label={label ?? 'Link'} field="type" description={'Select what you want to link.'}>
				<Block discriminateBy="story" label={'Stories'}>
					<SelectField label={undefined} options="Story.title" field="story" />
				</Block>
				<Block discriminateBy="chapter" label={'Chapters'}>
					<SelectField
						label={undefined}
						options="Chapter"
						field="chapter"
						renderOption={(accessor) => {
							const chapterTitle = accessor.getField('title').value
							const storyTitle = accessor.getField('story.title').value

							return (
								<>
									{chapterTitle} ({storyTitle})
								</>
							)
						}}
						optionsStaticRender={
							<>
								<Field field="title" />
								<Field field="story.title" />
							</>
						}
					/>
				</Block>
				<Block discriminateBy="url" label={'External link'}>
					<TextField label="Url" field="url" />
				</Block>
			</DiscriminatedBlocks>
		</HasOne>
	)
}, 'LinkField')
