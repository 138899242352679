import * as React from 'react'
import {
	Component,
	Field,
	HasMany,
	Repeater,
	TextField,
	ImageUploadField,
	TextareaField,
	Stack,
	HasOne,
	If,
	CheckboxField,
	useField,
	useRedirect,
	ToasterContext,
} from '@contember/admin'
import { LinkButton } from '../LinkButton'
import { usePreventLeavingWithUnsavedChanges } from '../../hooks/usePreventLeavingWithUnsavedChanges'
import { BlockEditor } from '../BlockEditor'
import { CleverMapsIdSelect } from '../CleverMapsIdSelect'
import { ProjectIdProvider } from '../ProjectIdContext'
import { useCleverMapsGetProjectSettings } from '../../hooks/cleverMaps/useCleverMapsGetProjectSettings'
import { useCleverMapsListProjectSettings } from '../../hooks/cleverMaps/useCleverMapsListProjectSettings'
import { useCleverMapsUpdateProjectSettings } from '../../hooks/cleverMaps/useCleverMapsUpdateProjectSettings'
import { useCleverMapsCanEdit } from '../../hooks/cleverMaps/useCleverMapsCanEdit'
import BrokenLinkMessage from '../BrokenLinkMessage'

type StoryFormEditProps = {
	isRecursive?: boolean
}

export const StoryEditForm = Component<StoryFormEditProps>(
	() => {
		usePreventLeavingWithUnsavedChanges()
		const projectId = useField<string>('cleverMapsId').value
		const redirect = useRedirect()
		const projectSettingsList = useCleverMapsListProjectSettings({ projectId })
		const projectSettings = useCleverMapsGetProjectSettings({ projectId, settingName: projectSettingsList?.content[0]?.name })
		useCleverMapsUpdateProjectSettings({ projectId, settings: projectSettings })
		const cantEditRole = useCleverMapsCanEdit({ projectId: projectId })
		const { showToast} = React.useContext(ToasterContext)
		
		React.useEffect(() => {
			if (cantEditRole === false) {
				redirect('storyList')
				showToast({ type:'error', message: <BrokenLinkMessage />})
			}
		}, [cantEditRole, redirect, showToast])
		

		return (cantEditRole ?
			<ProjectIdProvider field="cleverMapsId">
				<div className="flex mt-8">
					<div className="flex-grow w-full">
						<div>
							<div className="rounded-lg p-4 shadow-sm border border-slate-100">
								<div className="mb-4">
									<TextField field="title" label="Title of your story" />
								</div>
								<div className="flex">
									<div className="flex-grow">
										<Stack direction="vertical" gap="default">
											<TextField field="headerHeadline" label="Headline in header" />
											<TextField field="headerSubheading" label="Subheading in header" />
										</Stack>
									</div>
									<div className="w-96 ml-8">
										<ImageUploadField
											baseEntity="headerImage"
											urlField="url"
											widthField="width"
											heightField="height"
											label="Background image in header"
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="mt-10">
							<BlockEditor />
						</div>
						<div className="mt-10">
							<Stack direction="vertical">
								<CheckboxField field="useMobileHeader" label="Turn on hamburger menu" notNull />
								<CheckboxField field="useStyle" label="Turn on special styling" notNull />
								<If condition="[useStyle = true]">
									<div className="[&_.cui-stack]:block [&_textarea]:w-full">
										<TextareaField field="style" label="Write CSS here" minRows={15} />
									</div>
								</If>
							</Stack>
						</div>
					</div>
					<div className="ml-8">
						<div className="w-[400px]">
							<Stack direction="vertical">
								<Repeater field="chapters" label="Story chapters" addButtonText="Add chapter" sortableBy="order" enableRemoving={false}>
									<TextField field="title" label="Title" />
									<Stack direction="horizontal" justify="stretch">
										<LinkButton to="chapterEdit(id: $entity.id)">Edit chapter</LinkButton>
										<LinkButton intent="danger" to="storyChapterDelete(id: $entity.id)">
											Delete
										</LinkButton>
									</Stack>
								</Repeater>
								<LinkButton to="storyDuplicate(id: $entity.id)">Duplicate</LinkButton>
							</Stack>
						</div>
					</div>
				</div>
			</ProjectIdProvider> : null
		)
	},
	() => (
		<>
			<Field field="title" />
			<Field field="useStyle" />
			<Field field="style" />
			<Field field="useMobileHeader" />
			<Field field="cleverMapsId" />
			<Field field="headerSubheading" />
			<Field field="headerHeadline" />
			<HasOne field="headerImage">
				<Field field="url" />
				<Field field="width" />
				<Field field="height" />
			</HasOne>
			<BlockEditor />
			<HasMany field="chapters">
				<Field field="order" />
				<Field field="title" />
			</HasMany>
		</>
	),
	'StoryEditForm',
)

export const StoryCreateForm = Component(
	() => {
		usePreventLeavingWithUnsavedChanges()

		return (
			<ProjectIdProvider field="cleverMapsId">
				<div className="flex mt-8">
					<div className="flex-grow w-full">
						<div className="mb-8">
							<Stack direction="vertical" gap="default">
								<TextField field="title" label="Title of your story" />
								<CleverMapsIdSelect field="cleverMapsId" label="Choose project from CleverMaps" />
							</Stack>
						</div>
					</div>
				</div>
			</ProjectIdProvider>
		)
	},
	() => (
		<>
			<Field field="title" />
			<Field field="useStyle" />
			<Field field="style" />
			<Field field="useMobileHeader" />
			<Field field="cleverMapsId" />
			<Field field="headerSubheading" />
			<Field field="headerHeadline" />
			<HasOne field="headerImage">
				<Field field="url" />
				<Field field="width" />
				<Field field="height" />
			</HasOne>
			<BlockEditor />
			<HasMany field="chapters">
				<Field field="order" />
				<Field field="title" />
			</HasMany>
		</>
	),
	'StoryCreateForm',
)
