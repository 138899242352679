import * as React from 'react'
import { Stack, EntityAccessor, Button } from '@contember/admin'

import { CleverMapsSDKContext } from '../../cleverMapsSDK'
import { FieldTypeProps } from '../RichTextRenderer'

import { SingleSelectField } from '../../SingleSelectField'
import { RadioField } from '../../RadioField'
import { RangeField } from '../../RangeField'
import { MultiSelectField } from '../../MultiSelectField'
import { CheckboxesField } from '../../CheckboxesField'

export const createIframeFilterId = (iframeId: string, filterName: string) => `${iframeId}_${filterName}`

type CleverMapFormProps = { reference: { form: EntityAccessor }}

export type FiltersAdded = Record<string, { value?: string; values?: Array<string | boolean | number> }>

export const CleverMapForm = ({ reference }: CleverMapFormProps) => {
	const { activeFiltersCount, resetFiltersCount } = React.useContext(CleverMapsSDKContext)

	return (
		<div className="my-6 max-w-2xl mx-auto">
			<Stack direction="vertical" gap="large">
				{Array.from(reference.form.getEntityList('fields')).map((field) => {
					const fieldType: FieldTypeProps = field.getField<FieldTypeProps>('type').value!

					const Fields = {
						singleSelectField: <SingleSelectField field={field} reference={reference}></SingleSelectField>,
						multiSelectField: <MultiSelectField field={field} reference={reference}></MultiSelectField>,
						checkboxesField: <CheckboxesField field={field} reference={reference}></CheckboxesField>,
						radioField: <RadioField field={field} reference={reference}></RadioField>,
						rangeField: <RangeField field={field} reference={reference}></RangeField>,
					}

					return <>{Fields[fieldType]}</>
				})}
				{activeFiltersCount > 0 && (
					<Button
						intent="secondary"
						onClick={() => {
							resetFiltersCount()
						}}
					>
						Reset filters
					</Button>
				)}
			</Stack>
		</div>
	)
}
