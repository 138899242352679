import * as React from 'react'
import { Component, Field, useField } from '@contember/admin'
import { useCleverMapsCanEdit } from '../hooks/cleverMaps/useCleverMapsCanEdit'
import { Box, Skeleton } from '@mui/material'

export type StoryListEditableConditionProps = {
	children: React.ReactNode,
	skeletonWidth: number,
	skeletonVariant: 'rounded' | 'circular',
}

export default Component<StoryListEditableConditionProps>(
	({ children, skeletonWidth: width, skeletonVariant: variant }) => {
		const cleverMapsId = useField<string>('cleverMapsId').value
		const cantEditRole = useCleverMapsCanEdit({ projectId: cleverMapsId })
		if (cantEditRole === null) {
			return <Skeleton variant={variant} width={width} height={36} />
		}
		return <>{cantEditRole ? children : <Box sx={{ width, height: 36 }} />}</>
	},
	({ children }) => (
		<>
			<Field field='cleverMapsId' />
			{children}
		</>
	),
	'StoryListEditableCondition',
)