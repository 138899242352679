import { EntityAccessor } from '@contember/admin';
import { Autocomplete, Checkbox, FormControl, TextField } from '@mui/material';
import React, { useCallback } from 'react';
import { CleverMapsSDKContext } from './cleverMapsSDK';
import { resetIframeFilters } from '../services/iframeFilter';
import filterValueTransform from '../utils/filterValueTransform';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useHandleIFrameSubscription } from '../hooks/cleverMaps/useHandleIFrameSubscription';
import iframeValueTransform from '../utils/iframeValueTransform';

type MultiSelectFieldProps = {
    field: EntityAccessor;
    reference: { form: EntityAccessor };
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const MultiSelectField: React.FC<MultiSelectFieldProps> = ({ field, reference }) => {
    const fieldId = field.id as string
    const { iframes, activeFiltersCount, increaseFiltersCount, decreaseFiltersCount } = React.useContext(CleverMapsSDKContext)
    const cleverMapIds = Array.from(reference.form.getEntityList('cleverMaps')).map((cleverMap) => cleverMap.getField('id').value)
    const valuesArray = Array.from(field.getEntityList({ field: 'values' }))
    const filterName = field.getField<string>('name').value
    const [filterId, setFilterId] = React.useState<null | string>(null)
    const [filterValues, setFilterValues] = React.useState<null | Array<string>>(null)


    React.useEffect(() => {
        if (activeFiltersCount === 0) {
            resetIframeFilters(iframes, cleverMapIds, filterId, setFilterId, setFilterValues)
        }
    }, [activeFiltersCount, cleverMapIds, filterId, iframes])

    const addFilters = useCallback((...params) => {
        const values = params[1]?.values
        if (params[2].property === filterName) {
            increaseFiltersCount()
            if (!values) {
                setFilterId(params[0])
            } else {
                setFilterValues(values.map((value) => iframeValueTransform(value)))
                setFilterId(params[0])
            }
        }
    }, [filterName, increaseFiltersCount])

    useHandleIFrameSubscription(cleverMapIds, addFilters)

    const onValueChange = (newValue: {
        value: string;
        label: string;
    }[]) => {
        if (!newValue.length) {
            resetIframeFilters(iframes, cleverMapIds, filterId, setFilterId, setFilterValues)
            decreaseFiltersCount()
        } else {
            const value = newValue.map((value) => value.value)
            iframes
                .filter((iframe) => cleverMapIds.includes(iframe.cleverMapId))
                .forEach((it) => {
                    if (!filterValues && !filterId) {
                        it.iframe.message.addFilter(filterName, { values: value.map((value) => filterValueTransform(value)) }, filterName)
                    } else {
                        it.iframe.message.setFilter(filterId, { values: value.map((value) => filterValueTransform(value)) })
                        setFilterValues(value)
                    }
                })
        }
    }

    return (<FormControl key={fieldId} variant="outlined" size="small">
        <Autocomplete
            multiple
            limitTags={2}
            value={
                filterValues
                    ? filterValues.map((value) => {
                        const itemValue = valuesArray.find((item) => {
                            return item.getField<string>('value').value! === value
                        })
                        return {
                            value: itemValue.getField<string>('value').value!,
                            label: itemValue.getField<string>('label').value!,
                        }
                    })
                    : []
            }
            isOptionEqualToValue={(option, value) => {
                return option.value === value.value
            }}
            options={valuesArray.map((item) => ({
                value: item.getField<string>('value').value!,
                label: item.getField<string>('label').value!,
            }))}
            getOptionLabel={(option) => (option && typeof option != 'string' ? String(option.label) : '')}
            onChange={(event, newValue) => {
                onValueChange(newValue)
            }}
            disableCloseOnSelect
            disablePortal={true}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.label}
                </li>
            )}
            renderInput={(params) => <TextField {...params} label={<>{field.getField('label').value}</>} />}
        />
    </FormControl>)
}
