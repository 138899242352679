// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import { Layout } from '../components/Layout'

const LoginCallback = () => (
	<Layout>
		Authenticating, redirecting to original address.
	</Layout>
)

export default LoginCallback;
