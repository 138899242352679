import * as React from 'react'
import {
	Box,
	Button,
	Collapsible,
	Component,
	Dropdown,
	EditorRenderElementProps,
	EditorTransforms,
	Field,
	HasMany,
	HasOne,
	Icon,
	InitializeReferenceContentProps,
	Repeater,
	Stack,
	useEditor,
	useEntityList,
} from '@contember/admin'
import {ProjectIdContext} from '../ProjectIdContext'
import {useCleverMapsViewsMetadata} from '../../hooks/cleverMaps/useCleverMapsViewsMetadata'
import {useCleverMapsProject} from '../../hooks/cleverMaps/useCleverMapsProject'
import {useCleverMapsListDatasets} from '../../hooks/cleverMaps/useCleverMapsListDatasets'
import {CleverMapsMultiSelectField} from '../CleverMapsMultiSelectField'
import {FilterLinkField} from '../FilterLinkField'
import {BulkFilterContext} from '../BlockEditor'
import filterGroupsToPropertyNames from '../../utils/filterGroupsToPropertyNames'

export const InsertCleverMapBulkFilter = Component<InitializeReferenceContentProps>(
	({ onSuccess, onCancel }) => {
		const stackEntityAccessor = React.useContext(BulkFilterContext)
		const projectId = React.useContext(ProjectIdContext)
		const cleverMaps = useEntityList('targetCleverMaps')
		if (stackEntityAccessor) {
			cleverMaps.connectEntity(stackEntityAccessor)
		}
		const viewNames = React.useMemo(
			() => Array.from(cleverMaps).map((accessor) => accessor.getField<string>('viewName').value),
			[cleverMaps, cleverMaps.length],
		)

		const metaData = useCleverMapsViewsMetadata(projectId, viewNames)
		const project = useCleverMapsProject({ projectId })
		const datasets = useCleverMapsListDatasets({ projectId })

		const [collapsibleState, setCollapsibleState] = React.useState(false)

		const filterGroups = metaData?.content?.filterGroup
		const propertyNames = filterGroupsToPropertyNames(filterGroups, datasets)

		return (
			<div className="dialog-content-wrapper">
				<Stack direction="vertical" gap="large">
					{!stackEntityAccessor && <CleverMapsMultiSelectField mode="link" maxMenuHeight={125} />}
					<Button onClick={() => setCollapsibleState(!collapsibleState)}>{collapsibleState ? 'Close' : 'Open'} filter values</Button>
				</Stack>
				<HasOne
					field="form"
					onInitialize={(getAccessor) => {
						if (!getAccessor().existsOnServer && getAccessor().getField('id').value === null) {
							getAccessor().getField('id').asUuid.setToUuid()
						}
					}}
				>
					<Collapsible expanded={collapsibleState}>
						<Stack direction="vertical">
							<Repeater field="fields" label="Filters" sortableBy="order" addButtonText="Add field" initialEntityCount={0}>
								{project && propertyNames && filterGroups && (
									<FilterLinkField propertyNames={propertyNames} filterGroups={filterGroups} project={project} />
								)}
							</Repeater>
						</Stack>
					</Collapsible>
				</HasOne>
				<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1em', minWidth: '350px' }}>
					<Button onClick={onCancel}>{'Cancel'}</Button>
					<Button intent="primary" onClick={() => onSuccess({ createElement: { type: 'bulkFilterCleverMap' } })}>
						Insert
					</Button>
				</div>
			</div>
		)
	},
	() => (
		<>
			<HasOne field="form">
				<HasMany field="cleverMaps">
					<Field field="viewName" />
					<Field field="shareId" />
					<Field field="showMeasure" />
					<Field field="showCompare" />
					<Field field="showSearch" />
					<Field field="infoBoxDefaultExpanded" />
					<Field field="showProjectControls" />
					<Field field="showViewSwitch" />
					<Field field="showQuickShare" />
					<Field field="showQuickExport" />
					<Field field="fullScreenButton" />
					<Field field="interactivityAfterClick" />
					<Field field="themeLogoUrl" />
					<Field field="themePrimaryColor" />
					<Field field="name" />
					<HasMany field="forms" />
				</HasMany>
			</HasOne>
			<HasMany field="targetCleverMaps">
				<Field field="viewName" />
				<Field field="shareId" />
				<Field field="showMeasure" />
				<Field field="showCompare" />
				<Field field="showSearch" />
				<Field field="infoBoxDefaultExpanded" />
				<Field field="showProjectControls" />
				<Field field="showViewSwitch" />
				<Field field="showQuickShare" />
				<Field field="showQuickExport" />
				<Field field="fullScreenButton" />
				<Field field="interactivityAfterClick" />
				<Field field="themeLogoUrl" />
				<Field field="themePrimaryColor" />
				<Field field="name" />
				<HasMany field="forms" />
			</HasMany>
		</>
	),
	'InsertCleverMapBulkFilter',
)

export const BulkFilterCleverMapElement = (props: EditorRenderElementProps) => {
	const stackEntityAccessor = React.useContext(BulkFilterContext)
	const projectId = React.useContext(ProjectIdContext)
	const cleverMaps = useEntityList('targetCleverMaps')
	const viewNames = React.useMemo(
		() => Array.from(cleverMaps).map((accessor) => accessor.getField<string>('viewName').value),
		[cleverMaps, cleverMaps.length],
	)
	const metaData = useCleverMapsViewsMetadata(projectId, viewNames)
	const project = useCleverMapsProject({ projectId })
	const datasets = useCleverMapsListDatasets({ projectId })

	const filterGroups = metaData?.content?.filterGroup
	const propertyNames = filterGroupsToPropertyNames(filterGroups, datasets)

	const editor = useEditor()
	const [collapsibleState, setCollapsibleState] = React.useState(false)

	return (
		<span {...props.attributes} style={{ color: 'var(--cui-control-color)' }}>
			{props.children}
			<span contentEditable={false}>
				<Dropdown
					renderToggle={({ ref, onClick }) => (
						<button ref={ref as React.LegacyRef<HTMLButtonElement>} onClick={onClick} className="editorButton">
							<Icon blueprintIcon="filter-open" />
						</button>
					)}
				>
					<div className="dialog-content-wrapper">
						<Box>
							<Stack direction="vertical" gap="large">
								{!stackEntityAccessor && <CleverMapsMultiSelectField mode="link" />}
								<Button onClick={() => setCollapsibleState(!collapsibleState)}>{collapsibleState ? 'Close' : 'Open'} filter values</Button>
							</Stack>
							<HasOne
								field="form"
								onInitialize={(getAccessor) => {
									if (!getAccessor().existsOnServer && getAccessor().getField('id').value === null) {
										getAccessor().getField('id').asUuid.setToUuid()
									}
								}}
							>
								<Collapsible expanded={collapsibleState}>
									<Stack direction="vertical">
										<Repeater field="fields" label="Filters" sortableBy="order" addButtonText="Add field" initialEntityCount={0}>
											{project && propertyNames && filterGroups && (
												<FilterLinkField propertyNames={propertyNames} filterGroups={filterGroups} project={project} />
											)}
										</Repeater>
									</Stack>
								</Collapsible>
							</HasOne>
							<Stack direction="vertical">
								<Button intent="danger" onClick={() => EditorTransforms.unwrapNodes(editor, { at: [], match: (node) => node === props.element })}>
									Remove filter
								</Button>
							</Stack>
						</Box>
					</div>
				</Dropdown>
			</span>
		</span>
	)
}
