import { useCleverMapsPostEndpoint } from '../useCleverMapsPostEndpoint'

export interface UseCleverMapsUpdateProjectSettings$Input {
	projectId?: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	settings?: Record<string, any>,
}

export const useCleverMapsUpdateProjectSettings = ({ projectId, settings }: UseCleverMapsUpdateProjectSettings$Input) => {
	const trustedOrigins = settings?.content?.trustedOrigins
	const url = (projectId && settings?.id && !trustedOrigins?.includes(window.origin)) ?
		`/rest/projects/${projectId}/md/projectSettings/${settings.id}` : null

	const { response, error } = useCleverMapsPostEndpoint(
		url,
		settings && {
			name: settings.name,
			type: settings.type,
			content: {
				...settings.content,
				trustedOrigins: [...trustedOrigins ?? [], window.origin],
			}
		},
		{
			'If-Match': settings?.version,
			'X-CAN-STRICT-JSON-VALIDATION': 'false',
			'Content-Type': 'application/json',
		}
	)

	if (error) {
		if (error === 405) {
			return null
		}

		throw new Error(String(error))
	}

	return response
}
